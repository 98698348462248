import { loadStateData, getDataByKey, saveStateData } from './../Storage';
import Request from './../Request';

export const getToken = () => (getDataByKey('user') || {}).accessToken;

const getAuthExpiration = () => {
  const stateData = loadStateData() || {};
  const { user: { authExpiration = null } = {} } = stateData || {};
  const nextAuthExpiration = (authExpiration && authExpiration * 1000) || Date.now();

  return nextAuthExpiration;
};

export default {
  isAuthenticated: () => Boolean(getToken()),
  getToken,
  getAuthExpiration,
  getUser: () => getDataByKey('user'),
  login: (data) => Request.login(data)
    .then(user => {
      saveStateData({ user });

      return user;
    }),
  logout: () => {
    localStorage.clear();
    window.location.reload();
  }
};
