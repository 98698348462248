import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  InputLabel,
  InputAdornment,
  Card,
  MenuItem,
  Button,
  Tooltip,
  TextField,
  FormControl,
  Select
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import { Request } from '../../../services';

let i = 0;
const dataGridSchema = [
  {
    label: '#',
    render: (_, i) => (<td key={i+1} className="text-center">{i+1}</td>)
  },
  {
    label: 'First Name',
    key: 'firstName'
  },
  {
    label: 'Last Name',
    key: 'lastName'
  },
  {
    label: 'Email',
    key: 'email'
  },
  {
    label: 'Phone',
    key: 'phone'
  },
  {
    label: 'Role',
    key: 'role'
  },
  {
    label: 'Actions',
    key: '',
    render: (_, i) => (
      <td key="actions" className="text-center">
        <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <FontAwesomeIcon
            icon={['far', 'edit']}
            className="font-size-sm"
          />
        </Button>
        <Button className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <FontAwesomeIcon
            icon={['fas', 'times']}
            className="font-size-sm"
          />
        </Button>
      </td>
    )
  }
];

export default (props) => {
  const [pagination, setPagination] = useState([]);
  const [users, setUsers] = useState([]);
  const [entries, setEntries] = useState('1');

  const handleChange = useCallback((event) => {
    setEntries(event.target.value);
  }, []);

  useEffect(() => {
    Request.getUsers()
      .then(userData => {
        setUsers(userData.data);
        setPagination(userData.pagination);
      });
  }, []);

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header py-3">
          <div className="card-header--title font-size-lg"></div>
          <div className="card-header--actions">
            <Button size="small" className="btn-neutral-primary">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'plus-circle']} />
              </span>
              <span className="btn-wrapper--label">Add User</span>
            </Button>
          </div>
        </div>
        <div className="d-flex justify-content-between px-4 py-3">
          <div className="d-flex align-items-center">
            <span>Show</span>
            <FormControl size="small" variant="outlined" className="mx-3">
              <InputLabel id="select-entries-label">Entries</InputLabel>
              <Select
                labelId="select-entries-label"
                id="select-entries"
                value={entries}
                onChange={handleChange}
                label="Entries">
                <MenuItem className="mx-2" value={1}>
                  All entries
                </MenuItem>
                <MenuItem className="mx-2" value={10}>
                  10
                </MenuItem>
                <MenuItem className="mx-2" value={15}>
                  15
                </MenuItem>
                <MenuItem className="mx-2" value={20}>
                  20
                </MenuItem>
                <MenuItem className="mx-2" value={25}>
                  25
                </MenuItem>
                <MenuItem className="mx-2" value={30}>
                  30
                </MenuItem>
              </Select>
            </FormControl>
            <span>entries</span>
          </div>
          <div className="search-wrapper">
            <TextField
              variant="outlined"
              size="small"
              id="input-search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>
        <div className="divider" />
        <div className="table-responsive-md">
          <Table className="table table-hover text-nowrap mb-0">
            <thead>
              <tr>
                { dataGridSchema.map(({ label }, index) => (<th key={`${index}-th`} className="bg-white text-center">{label}</th>)) }
              </tr>
            </thead>
            <tbody>
              {
                users.map((user, index) => (
                  <tr key={index}>
                    {
                      dataGridSchema.map(({ key, render }, subIndex) => render
                        ? render(user, index)
                        : (<td key={`${index+1}-${subIndex}-td`} className="text-center">{user[key]}</td>)
                      )
                    }
                  </tr>
                  )
                )
              }
            </tbody>
          </Table>
        </div>
        <div className="card-footer py-3 d-flex justify-content-between">
          <Pagination
            className="pagination-second"
            variant="outlined"
            count={10}
          />
          <div className="d-flex align-items-center">
            <span>Show</span>
            <FormControl size="small" variant="outlined" className="mx-3">
              <InputLabel id="select-entries-label">Entries</InputLabel>
              <Select
                labelId="select-entries-label"
                id="select-entries"
                value={entries}
                onChange={handleChange}
                label="Entries">
                <MenuItem className="mx-2" value={1}>
                  All entries
                </MenuItem>
                <MenuItem className="mx-2" value={10}>
                  10
                </MenuItem>
                <MenuItem className="mx-2" value={15}>
                  15
                </MenuItem>
                <MenuItem className="mx-2" value={20}>
                  20
                </MenuItem>
                <MenuItem className="mx-2" value={25}>
                  25
                </MenuItem>
                <MenuItem className="mx-2" value={30}>
                  30
                </MenuItem>
              </Select>
            </FormControl>
            <span>entries</span>
          </div>
        </div>
      </Card>
    </>
  );
}
