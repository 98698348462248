import Account from './Account';
import ControlDocs from './ControlDocs';
import ControlDocsModify from './ControlDocsModify';
import CustomFields from './CustomFields';
import DocCreate from './DocCreate';
import DocDetails from './DocDetails';
import Error404 from './Error404';
import IdeaLibrary from './IdeaLibrary';
import Login from './Login';
import RecoverOverlay from './RecoverOverlay';
import RegisterOverlay from './RegisterOverlay';
import SystemSettings from './SystemSettings';
import Tickets from './Tickets';
import Dashboard from './Dashboard';
import Users from './Users';

export {
  Account,
  ControlDocs,
  ControlDocsModify,
  CustomFields,
  DocCreate,
  DocDetails,
  Error404,
  IdeaLibrary,
  Login,
  RecoverOverlay,
  RegisterOverlay,
  SystemSettings,
  Tickets,
  Dashboard,
  Users
}
