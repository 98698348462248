import React from 'react';
import {
  Table,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from '@material-ui/core';
import {
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import {
  MONTH_ABBR_SHORT,
  TITLES,
  THEME_SIZES,
  GDOC_TYPE,
  SDOC_TYPE,
  IDOC_TYPE,
  BDOC_TYPE,
  UOM_FORMATS
} from '../../../constants';
import { getTotalByGridValues, round, uomFormat } from '../../../utils/common';
import { useTableResponsiveStyles } from '../../../theme/styles';
import { useExpansionPanelStyles } from '../../pages/Dashboard/styles';
import Chart from './../Chart';

const prepareGraphData = (groupData, uom) => {
  const { categories, data } = Object.keys(groupData).reduce((result, year) => {
    return {
      categories: [
        ...result.categories,
        ...groupData[year].map((_, index) => new Date(year, index, '01').toLocaleString('US', { year: 'numeric', month: 'short'  }))
      ],
      data: [
        ...result.data,
        ...groupData[year]
      ],
    }
  }, { categories: [], data: [] });

  return { categories, series: [{ name: uom, data }] }
};
const GridGroup = (props) => {
  const { docType, label, ma, groupData = {}, isModal = false } = props;
  const ExpansionPanelStyles = useExpansionPanelStyles();

  const tableResponsiveStyles = useTableResponsiveStyles({
    columns: [TITLES.YEAR, ...MONTH_ABBR_SHORT, TITLES.TOTAL],
    breakpoint: isModal ? THEME_SIZES.md : THEME_SIZES.lg
  });

  return (
    <div className="m-2">
      <div className="card-header--title font-weight-bold font-size-lg text-lg-center">
        { ma ? `${label} - ${ma}` : label }
      </div>
      {
        Object.keys(groupData).map((uom, index) => (
          <React.Fragment key={uom}>
            <div className="font-weight-bold font-size-sm text-sm-left mt-2">{uom}</div>
            <Table className={
              `grid-group table table-hover table-bordered text-nowrap mt-1 ${tableResponsiveStyles.root}`
            }>
              <thead className={index % 2 !== 0 ? 'thead-dark' : 'thead-light'}>
              <tr>
                <th className="text-center">{TITLES.YEAR}</th>
                {
                  MONTH_ABBR_SHORT.map((monthName, subIndex) =>
                    (<th key={`${subIndex}-th`} className="text-center">{monthName}</th>))
                }
                <th className="text-center">{TITLES.TOTAL}</th>
              </tr>
              </thead>
              {
                Object.keys(groupData[uom]).map(
                  (year, index) => (
                    <React.Fragment key={`grid-details-${index}`}>
                      <tbody>
                      <tr>
                        <td className="text-center">
                          {year}
                        </td>
                        {
                          groupData[uom][year].map((val, subIndex) => (
                            <td key={`${index}-${subIndex}-td`} className="text-center">
                              { uomFormat(uom, val) }
                            </td>
                          ))
                        }
                        <td className="text-center">
                          {uomFormat(uom, round(groupData[uom][year].reduce((a, b) => (Number(a) + Number(b)), 0)))}
                        </td>
                      </tr>
                      </tbody>
                    </React.Fragment>
                  )
                )
              }
            </Table>
            <div className="font-weight-bold font-size-sm text-sm-right pr-3">
              Total: { uomFormat(uom, getTotalByGridValues(groupData[uom])) }
            </div>
            <ExpansionPanel classes={ExpansionPanelStyles}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-label="Expand"
              >
                <div className="font-weight-bold font-size-md text-lg-left w-100">
                  Chart {uom}
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails classes={ExpansionPanelStyles}>
                <Chart
                  {...prepareGraphData(groupData[uom], uom)}
                  title={{ text: uom }}
                  chart={{ type: [GDOC_TYPE, SDOC_TYPE, IDOC_TYPE].includes(docType) ? 'line' : 'bar' }}
                  label={label}
                  ma={ma}
                  uom={uom}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </React.Fragment>
        ))
      }
    </div>
  );
}

export default (props) => {
  const { groupData = {}, docType, selectedFieldItems } = props;
  const nextGroupData = docType === BDOC_TYPE ? { [selectedFieldItems?.Fields?.bDocUOM || UOM_FORMATS.CURRENCY]: groupData } : groupData;

  const [MA] = Object.keys(nextGroupData);
  const [UOM] = Object.keys(nextGroupData[MA] || {});
  const [YEAR] = Object.keys((nextGroupData[MA] || {})[UOM] || {});
  const isGroupByMaUom = Array.isArray(nextGroupData?.[MA]?.[UOM]?.[YEAR]);

  return isGroupByMaUom
    ? Object.keys(nextGroupData).map(ma => (
        <GridGroup
          { ...props }
          key={ma}
          ma={ma}
          groupData={nextGroupData[ma]}
        />
      ))
    : (
      <GridGroup
        { ...props }
        groupData={nextGroupData}
      />
    )
  ;
}
