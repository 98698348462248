import { ulid } from 'ulid';
import {
  DOC_HIERARCHY,
  DOC_LEVELS,
  IDOC_TYPE,
  FIELD_TITLES,
  DISPLAY_CONTROLLERS,
  YEAR_MONTHS_COUNT,
  UOM_FORMATS,
  RDOC_TYPE,
  CONTROL_DOC_CDOC
} from '../constants';

const DISPLAY_CONTROLLERS_VALUES = Object.values(DISPLAY_CONTROLLERS);

export const getParentTypesByDocs = (docs) => {
  return DOC_LEVELS.find(docLevels => Object.keys(docs).some(docType => docLevels.includes(docType)))
}

export const getChildTypes = (docType) => DOC_HIERARCHY[docType];

export const getParentType = (docType) =>
  Object.keys(DOC_HIERARCHY).find(parentType => DOC_HIERARCHY[parentType].includes(docType));

export const getConfigsDocFields = docConfig => Object.keys(FIELD_TITLES).reduce((result, field) => {
  const fields = (docConfig[field] || [])
    .filter(objField => !!objField.DisplayController && DISPLAY_CONTROLLERS_VALUES.includes(objField.DisplayController))
    .map(objField => objField.FieldName);

  return [...result, ...fields];
}, []);

export const getNotConfigsDocFields = (doc = {}, docConfig) => {
  const configFields = getConfigsDocFields(docConfig);

  return Object.keys(doc)
    .filter(key => !configFields.includes(key))
    .reduce((result, key) => ({ ...result, [key]: doc[key] }), [])
};

export const composeDoc = (doc, docConfig, docType) => {
  const mapFields = objField => {
    const { Label, ToolTip, DisplayPosition, DisplayController, FieldName, Items, ...props } = objField;

    return {
      label: Label,
      toolTip: ToolTip,
      displayPosition: DisplayPosition,
      displayController: DisplayController,
      field: FieldName,
      source: Items || [],
      value: doc[FieldName],
      ...props
    }
  };
  const titleName = doc[`${docType}Name`];

  return Object.keys(FIELD_TITLES).reduce((result, field) => ({
    ...result,
    [field]: (docConfig[field] || [])
      .filter(objField => !!objField.DisplayController && DISPLAY_CONTROLLERS_VALUES.includes(objField.DisplayController))
      .map(mapFields)
  }), {
    notGrouped: getNotConfigsDocFields(doc, docConfig),
    nodeId: doc[`${docType}Id`] || doc['Id'],
    type: docType,
    collection: docConfig.TargetCollection,
    clientName: titleName ? `${docConfig.DocumentType} - ${titleName}` : docConfig.DocumentType
  });
};

const getData = ({ docsConfig, docs, docType, childQuery }) => {
  const docConfig = docsConfig[docType];
  const listDocs = !childQuery ? docs[docType] : (docs[docType] || []).filter(doc => doc[childQuery.field] === childQuery.value);

  return listDocs.map(doc => {
    const isChildrens = (getChildTypes(docType) || []).some(
      docChildType => (docs[docChildType] || [])
        .filter(
          childDoc => {
            const { getCondition } = [
              {
                condition : docChildType === IDOC_TYPE && docType === IDOC_TYPE,
                getCondition: () => childDoc[`${docType}DependencyId`] === doc[`${docType}Id`]
              },
              {
                condition : docChildType === IDOC_TYPE && docType !== IDOC_TYPE,
                getCondition: () => !childDoc[`${docType}DependencyId`] && childDoc[`${docType}Id`] === doc[`${docType}Id`]
              },
              {
                condition : true,
                getCondition: () => childDoc[`${docType}Id`] === doc[`${docType}Id`]
              },
            ].find(({ condition }) => condition)

            return getCondition()
          }
        ).length
    );
    const composeFields = composeDoc(doc, docConfig, docType);
    const notification = [RDOC_TYPE].includes(docType) && !doc[`${CONTROL_DOC_CDOC}Id`];

    return {
      ...composeFields,
      notification,
      ...(!isChildrens
        ? {}
        : {
          children: getChildTypes(docType).reduce(
            (result, docChildType) => {
              const { getQuery } = [
                {
                  condition : docChildType === IDOC_TYPE && docType === IDOC_TYPE,
                  getQuery: () => ({ field: `${docType}DependencyId`, value: doc[`${docType}Id`] })
                },
                {
                  condition : true,
                  getQuery: () => ({ field: `${docType}Id`, value: doc[`${docType}Id`]})
                },

              ].find(({ condition }) => condition)

              return [
                ...result,
                ...getData({ docsConfig, docs, docType: docChildType, childQuery: getQuery()})
              ]
            }, [])
        }
      )
    }
  });
};

export const prepareTree = ({ docsConfig, docs }) =>
  getParentTypesByDocs(docs).reduce((result, docType) => [...result, ...getData({ docsConfig, docs, docType })], []);

export const findItem = (items, id) => {
  const find = (items) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].nodeId === id ) return items[i];

      if (items[i].children) {
        const item = find(items[i].children);

        if (item && item.nodeId === id) return item
      }
    }
  };

  return find(items);
};

export const getExpandIds = (items) => {
  const getChildrenIds = (result, item) => {
    if (item.children) return [...result, item.nodeId, ...item.children.reduce(getChildrenIds, [])];

    return [...result, item.nodeId];
  };

  return items.reduce(getChildrenIds, []);
};


export const modifyItems = (items, inputItem) => {
  const mofidyItem = (item) => {
    if (item.nodeId === inputItem.id || item.nodeId === inputItem.nodeId)
      return { ...item, ...inputItem };

    if (item.nodeId !== inputItem.id && item.children)
      return { ...item, children: item.children.map(mofidyItem) };

    if (item.nodeId !== inputItem.id && !item.children)
      return item;
  };

  return items.map(mofidyItem);
};

export const removeItems = (items, id) => {
  const removeItem = (item) => {
    if (!item.children) return item;

    return {
      ...item,
      children: item.children.filter(({ nodeId }) => nodeId !== id).map(removeItem)
    }
  };

  return items.map(removeItem).filter(({ nodeId }) => nodeId !== id);
};

export const generateId = (docType = '') => `${docType.toUpperCase()}_${ulid()}`;
export const AddItem = ({ items, parentId, docType, generatedId, docConfig }) => {
  if (!parentId) return [
    ...items,
    { ...composeDoc({ [`${docType}Id`]: generatedId }, docConfig, docType) }
  ];

  const mofidyItem = (item) => {
    if (item.nodeId === parentId)
      return {
        ...item,
        children: [
          ...(item.children || []).filter(({ type }) => type === docType),
          { ...composeDoc({ [`${docType}Id`]: generatedId }, docConfig, docType) },
          ...(item.children || []).filter(({ type }) => type !== docType)
        ]
      };


    if (item.nodeId !== parentId && item.children)
      return { ...item, children: item.children.map(mofidyItem) };

    if (item.nodeId !== parentId && !item.children)
      return item;
  };

  return items.map(mofidyItem);
};

export const prepareDbDoc = ({ selectedItem, selectedFieldItems, parentId, docType }) => {
  const nextFieldsTypes = Object.keys(selectedFieldItems).reduce((result, fieldsType) => {
    const modifyItems = selectedFieldItems[fieldsType];

    return {
      ...result,
      [fieldsType]: [
        ...(selectedItem[fieldsType] || []).map(
          (fieldObj) => modifyItems[fieldObj.field] === undefined
            ? fieldObj
            : { ...fieldObj, value: modifyItems[fieldObj.field] }
        )
      ]
    }
  }, {});

  const nextSelectedItem = { ...selectedItem, ...nextFieldsTypes };
  const { notGrouped = {} } = nextSelectedItem;
  const parentType = getParentType(docType);

  const dbDoc = Object.keys(FIELD_TITLES).reduce((result, fieldsType) => ({
    ...result,
    ...nextSelectedItem[fieldsType].reduce(((fieldResult, { field, value }) =>
        (![undefined, null, ''].includes(value) ? { ...fieldResult, [field]: value } : fieldResult)
    ), {})
  }), parentType && parentId ? { ...notGrouped, [`${parentType}Id`]: parentId } : notGrouped);

  return dbDoc;
}

export const getParentDocId = ({ id, docType, docs }) => {
  const parentType = getParentType(docType);

  if (!parentType) return undefined;

  const doc = docs[docType].find(doc => doc[`${docType}Id`] === id || doc['Id'] === id);

  if (!doc) return undefined;

  return doc[`${parentType}Id`];
};

export const findDoc = ({ id, docType, docs }) => (docs?.[docType] || []).find(({ [`${docType}Id`]: docId }) => docId === id);

export const round = (num) => Math.round(num * 100) / 100;

export const calculateGridData = ({ docType, defaultValues, TimeFields, docConfig }) => {
  const isCalcGridData = docType && TimeFields &&
    TimeFields[`${docType}PerPeriod`] &&
    TimeFields[`${docType}PeriodType`] &&
    TimeFields[`${docType}NumberOfYears`] !== undefined && (
      defaultValues[`${docType}PerPeriod`] !== TimeFields[`${docType}PerPeriod`] ||
      defaultValues[`${docType}PeriodType`] !== TimeFields[`${docType}PeriodType`] ||
      defaultValues[`${docType}NumberOfYears`] !== TimeFields[`${docType}NumberOfYears`] ||
      defaultValues[`${docType}StartDate`] !== TimeFields[`${docType}StartDate`] ||
      defaultValues[`${docType}Total`] !== TimeFields[`${docType}Total`]
    )
  ;

  if (!isCalcGridData) return;

  const gridParams = docConfig[FIELD_TITLES.TimeFields].find(({ FieldName }) => FieldName === `${docType}GridValues`);
  const {
    [`${docType}PerPeriod`]: xDocPerPeriod,
    [`${docType}PeriodType`]: xDocPeriodType,
    [`${docType}NumberOfYears`]: xDocNumberOfYears,
    [`${docType}StartDate`]: xDocStartDate
  } = TimeFields;

  const startDate = new Date(xDocStartDate || Date.now());
  const year = startDate.getFullYear();
  const countElements = YEAR_MONTHS_COUNT * xDocNumberOfYears;
  const evenValue = round(xDocPerPeriod / gridParams.NumberOfDivisions[xDocPeriodType]);

  const allValues = Array(countElements).fill(evenValue).fill(0, 0, startDate.getMonth());
  const gridValues = allValues
    .reduce((acc, value, i) => {
      if ((i % YEAR_MONTHS_COUNT) === 0) acc.push([]);
      acc[acc.length - 1].push(value);

      return acc;
    }, [])
    .reduce((result, value, i) => ({ ...result, [year+i]: value }), {})
  ;
  const total = round(allValues.reduce((a, b) => (Number(a) + Number(b)), 0));

  return {
    gridValues,
    total
  }
};

export const getTotalByGridValues = (gridValues) => Object.keys(gridValues).reduce(((sum, year) => {
  const yearTotal = round(gridValues[year].reduce((a, b) => (Number(a) + Number(b)), 0));

  return sum + yearTotal
}) ,0);

export const composeGridValues = ({ gridValues, year, index, val }) => {
  const nextGridValues = gridValues[year]
    .filter((_, i) => i !== index);
  nextGridValues.splice(index, 0, round(val));

  return {
    ...gridValues,
    [year]: nextGridValues
  }
};

export const uomFormat = (format, value) => {
  const formatFunctions = {
    [UOM_FORMATS.CURRENCY]: () => Number(value) < 0
      ? `(${Math.abs(Number(value)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })})`
      : Number(value).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    [UOM_FORMATS.TIME]: () => Number(value).toLocaleString('en-US', { style: 'decimal' })
  };

  return formatFunctions[format] ? formatFunctions[format]() : value;
}

export const haveParent = ({ doc, docType }) => {
  const parentType = getParentType(docType);

  return !!doc[`${parentType}Id`]
}

export const getTopParentId = ({ doc, docType, docs }) => {
  const parentType = getParentType(docType);

  if (!parentType) return doc[`${docType}Id`];

  const parentId = doc[`${parentType}Id`];

  if (!parentId) return doc[`${docType}Id`];

  const parentDoc = findDoc({ id: parentId, docType: parentType, docs });

  if (!parentDoc) return doc[`${docType}Id`];

  return getTopParentId({ doc: parentDoc, docType: parentType, docs });
};

export const getIdsTopParents = ({ searchDocs = [], docs }) =>
  searchDocs.reduce(((result, { doc, docType }) => {
    const topParent = getTopParentId({ doc, docType, docs });

    return [...result, topParent]
  }), []);
