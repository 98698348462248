import { stringify } from 'querystring';
import { getAxios } from './../../utils/axios';
import { DOC_TYPES } from '../../constants';

export const getConfiguration = () =>
  getAxios().get('/configuration');

export const createClientConfiguration = (data) =>
  getAxios().post('/configuration', data);

export const updateClientConfiguration = (data) =>
  getAxios().put('/configuration', data);

export const getDocsConfig = (docType = DOC_TYPES.xDocs) =>
  getAxios().get('/documents-config')
    .then(response => docType ? response[docType] : response);

export const getDocs = ({ Collection, ...query } = {}) =>
  getAxios().get(`/documents${ Collection ? `/${Collection}` : '-all' }?${stringify(query)}`)
    .then(response =>
      Collection
        ? response
        : response.reduce((result, item) => ({
            ...result,
            ...Object.keys(item).reduce((r, k) => ({ ...r, [k]: item[k] }), {})
          }), {})
    );

export const createDoc = (collection, data) =>
  getAxios().post(`/documents/${collection}/`, data);

export const updateDoc = (collection, id, data) =>
  getAxios().put(`/documents/${collection}/${id}`, data);

export const removeDoc = (collection, id) =>
  getAxios().delete(`/documents/${collection}/${id}`);

export const getControlDocs = ({ docType, ...query } = {}) =>
  getAxios().get(`/control-docs/${ docType ? docType : '' }?${stringify(query)}`)
    .then(response =>
      docType
        ? response
        : response.reduce((result, item) => ({
          ...result,
          ...Object.keys(item).reduce((r, k) => ({ ...r, [k]: item[k] }), {})
        }), {})
    );

export const createControlDoc = (docType, data) =>
  getAxios().post(`/control-docs/${docType}`, data);

export const updateControlDoc = (docType, id, data) =>
  getAxios().put(`/control-docs/${docType}/${id}`, data);

export const removeControlDoc = (docType, id) =>
  getAxios().delete(`/control-docs/${docType}/${id}`);

export const getControlDoc = (docType, id) =>
  getAxios().get(`/control-docs/${docType}/${id}`);

