import React from 'react';
import { Table, TextField } from '@material-ui/core';
import { FIELD_TITLES, MONTH_ABBR_SHORT, THEME_SIZES, TITLES } from '../../../constants';
import { round } from '../../../utils/common'
import { useTableResponsiveStyles } from '../../../theme/styles';

export default (props) => {
  const { selectedFieldItems, handleGridChange, docType, fieldsType, isModal = false } = props;
  const GridValues = fieldsType
    ? selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}GridValues`]
    : selectedFieldItems?.[`${docType}GridValues`];

  const tableResponsiveStyles = useTableResponsiveStyles({
    columns: [TITLES.YEAR, ...MONTH_ABBR_SHORT, TITLES.TOTAL],
    breakpoint: isModal ? THEME_SIZES.md : THEME_SIZES.lg
  });

  return GridValues
    ? (
      <Table className={`table table-hover table-bordered text-nowrap mt-4 mb-4 ${tableResponsiveStyles.root}`}>
        {
          Object.keys(GridValues).map(
            (year, index) => (
              <React.Fragment key={`grid-details-${index}`}>
                <thead>
                <tr>
                  <th className="text-center">{TITLES.YEAR}</th>
                  {
                    GridValues[year].map((_, subIndex) =>
                      (<th key={`${index}-${subIndex}-th`} className="text-center">{MONTH_ABBR_SHORT[subIndex]}</th>))
                  }
                  <th className="text-center">{TITLES.TOTAL}</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td className="text-center">{year}</td>
                  {
                    GridValues[year].map((val, subIndex) => (
                      <td key={`${index}-${subIndex}-td`} className="text-center">
                        <TextField
                          variant="outlined"
                          type='number'
                          value={val}
                          onChange={e => handleGridChange(year, subIndex, e.target.value)}
                        />
                      </td>
                    ))
                  }
                  <td className="text-center">
                    {round(GridValues[year].reduce((a, b) => (Number(a) + Number(b)), 0))}
                  </td>
                </tr>
                </tbody>
              </React.Fragment>
            )
          )
        }
      </Table>
    )
    : (<></>);
}
