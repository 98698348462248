import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Grid, Input, Card, FormControl, Button } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { Request } from '../../../services';

function TextMaskPhone(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '+',
        '1',
        ' ',
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
      showMask
      guide={true}
    />
  );
}
TextMaskPhone.propTypes = {
  inputRef: PropTypes.func.isRequired
};

export default function LivePreviewExample() {
  const [fields, setFields] = useState({
    phone: '+1 (456) 456-4564'
  });
  const [errors, setErrors] = useState([]);

  const handleChange = (name) => (event) => {
    setFields({
      ...fields,
      [name]: event.target.value
    });
  };

  const handleSubmit = useCallback(() => {
    setErrors([]);

    console.log('fields: ', fields)

  },[JSON.stringify(fields)]);

  useEffect(() => {
    Request.getProfile().then(user => {
      setFields(user)
    })
  }, [])

  return (
    <>
      <Card className="shadow-xxl px-4 py-5">
        <Grid item md={8} lg={6} className="mx-auto p-0">
          <div className="heading-3">First Name</div>
          <FormControl fullWidth size="medium">
            <Input
              value={fields.firstName}
              onChange={handleChange}
              name="firstName"
              id="first-name"
            />
          </FormControl>

          <div className="my-3" />

          <div className="heading-3">last Name</div>
          <FormControl fullWidth size="medium">
            <Input
              value={fields.lastName}
              onChange={handleChange}
              name="lastName"
              id="first-name"
            />
          </FormControl>

          <div className="my-3" />

          <div className="heading-3">Email</div>
          <FormControl fullWidth size="medium">
            <Input
              value={fields.email}
              onChange={handleChange}
              name="email"
              id="email"
            />
          </FormControl>

          <div className="my-3" />

          <div className="heading-3">Phone numbers</div>
          <FormControl fullWidth size="medium">
            <Input
              value={fields.phone}
              onChange={handleChange}
              name="phone"
              id="phone-mask-input"
              inputComponent={TextMaskPhone}
            />
          </FormControl>

          <div className="my-3" />

          <div className="heading-3">Password</div>
          <FormControl fullWidth size="medium">
            <Input
              type='password'
              value={fields.password}
              onChange={handleChange}
              name="lastName"
              id="first-name"
            />
          </FormControl>

          <div className="my-3" />

          <div className="heading-3">Confirm Password</div>
          <FormControl fullWidth size="medium">
            <Input
              type='password'
              value={fields.confirmPassword}
              onChange={handleChange}
              name="email"
              id="email"
            />
          </FormControl>

          <div className="text-center py-4">
            <Button
              fullWidth
              className="text-uppercase font-weight-bold font-size-sm mt-4 btn-primary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </Grid>
      </Card>
    </>
  );
}
