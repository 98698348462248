import { makeStyles } from "@material-ui/core";

const useDialogStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const useSpanHeaderStyles = makeStyles(() => ({
  root: {
    marginRight: '10px',
  },
}));

const useExpansionPanelStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    display: 'block',
    margin: '15px 0px'
  },
}));

const useFieldsBlockStyles = makeStyles(() => ({
  right: {
    display: (props = {}) => {
      const { windowDimensions: { width } = {} } = props;

      return width && width > 1400 ? 'inline-block' : 'block !important';
    },
    minWidth: '500px'
  }
}));

export {
  useDialogStyles,
  useSpanHeaderStyles,
  useExpansionPanelStyles,
  useFieldsBlockStyles
}
