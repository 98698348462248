import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Table,
  Card,
  Button,
  TextField,
  Grid,
  Dialog,
  Chip, MenuItem
} from '@material-ui/core';

import { FIELD_TITLES, TITLES } from '../../../constants';
import { Request } from '../../../services';
import { setPageTitle } from '../../../reducers/ThemeOptions';
import {
  findItem,
  prepareTree,
  getParentDocId,
  getChildTypes,
  calculateGridData,
  findDoc,
  composeGridValues,
  getTotalByGridValues,
  haveParent,
  composeDoc, getParentType
} from '../../../utils/common';
import { useHideBorderStyles } from './../../../assets/styles';
import { useTableResponsiveStyles } from '../../../theme/styles';

import { WrapperSimple } from '../../layout-components';
import { RenderField } from './../../controls';

let i = 0;
const dataGridSchema = [
  {
    label: '#',
    render: ({ doc, index: i }) => (<td key={i + 1} className="text-center">{i + 1}</td>)
  },
  {
    label: 'Name',
    key: 'clientName'
  },
  {
    label: 'Description',
    key: 'xDocDesc',
    render: ({ doc, index: i }) => {
      const field = (doc.Fields || []).find(Field => Field.field.includes('ShortDesc'));

      return (<td key={`xDocDesc-${i}-td`} className="text-center">{(field || {}).value || ''}</td>)
    }
  },
  {
    label: 'Exp Value',
    key: 'exp'
  },
  {
    label: 'Team Leader',
    key: 'leader'
  },
  {
    label: 'Sponsor',
    key: 'sponsor',
    render: ({ doc, index: i }) => {
      const field = (doc.Fields || []).find(Field => Field.field.includes('Sponsor'));

      return (<td key={`sponsor-${i}-td`} className="text-center">{(field || {}).value}</td>)
    }
  },
  {
    label: 'Managment Area',
    key: 'ManagmentArea'
  },
  {
    label: 'Actions',
    key: '',
    render: ({ doc, index: i, history, setRemoveDoc, toggleModal }) => {
      return (<td key="actions" className="text-center">
        <Button
          className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/docs/${doc.type}/${doc.nodeId}`);
          }}
        >
          <FontAwesomeIcon
            icon={['far', 'edit']}
            className="font-size-sm"
          />
        </Button>
          <Button
            className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={(e) => {
              e.preventDefault();
              setRemoveDoc(doc);
              toggleModal();
            }}
          >
            <FontAwesomeIcon
              icon={['fas', 'times']}
              className="font-size-sm"
            />
          </Button>
      </td>)
    }
  }
];

const getChildDocs = (doc) => {
  const getChilds = (result, doc) => {
    if (doc.children) return [...result, doc, ...doc.children.reduce(getChilds, [])];

    return [...result, doc];
  };

  return (doc.children || []).reduce(getChilds, [doc])
}


const DocDetails = (props) => {
  const history = useHistory();
  const { setPageTitle, match: { params: { docId, docType } = {} } = {} } = props;
  const [doc, setDoc] = useState({});
  const [selectedFieldItems, setSelectedFieldItems] = useState({});
  const [childDocs, setChildDocs] = useState([]);
  const [docsConfig, setDocsConfig] = useState();
  const [docs, setDocs] = useState();
  const [removeDoc, setRemoveDoc] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [isHaveParent, setIsHaveParent] = useState(true);
  const [parentId, setParentId] = useState('');
  const [parents, setParents] = useState([]);

  const tableResponsiveStyles = useTableResponsiveStyles({
    columns: dataGridSchema.map(({ label }) => label),
    additionalStyles: {
      '& th, td': {
        padding: '5px',
        minHeight: '35px'
      }
    }
  });
  const DivStyles = useHideBorderStyles();

  const isShowAggregations = Object.values(selectedFieldItems?.[FIELD_TITLES.Aggregations] || {}).every(value => !!value);

  const defaultValues = findDoc({ id: docId, docType, docs });
  useEffect(() => {
    const { TimeFields } = selectedFieldItems || {};
    const docConfig = docsConfig?.[docType];
    const data = calculateGridData({ docType, defaultValues, TimeFields, docConfig });

    if (!data) return;

    setSelectedFieldItems({
      ...selectedFieldItems,
      [FIELD_TITLES.TimeFields]: {
        ...selectedFieldItems[FIELD_TITLES.TimeFields],
        [`${docType}GridValues`]: data.gridValues,
        [`${docType}Total`]: data.total,
        ...(
          !selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}StartDate`]
            ? { [`${docType}StartDate`]: new Date() }
            : {}
        )
      }
    });
  }, [
    selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}PerPeriod`],
    selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}PeriodType`],
    selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}NumberOfYears`],
    selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}StartDate`]
  ]);

  const handleGridChange = useCallback((year, index, val) => {
    if (!selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}GridValues`]) return;

    const nextDocGridValues = composeGridValues({
      gridValues: selectedFieldItems[FIELD_TITLES.TimeFields][`${docType}GridValues`],
      year,
      index,
      val
    });

    setSelectedFieldItems({
      ...selectedFieldItems,
      [FIELD_TITLES.TimeFields]: {
        ...selectedFieldItems[FIELD_TITLES.TimeFields],
        [`${docType}GridValues`]: nextDocGridValues,
        [`${docType}Total`]: getTotalByGridValues(nextDocGridValues)
      }
    });
  }, [selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}GridValues`]]);


  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const setDefaultFieldsData = useCallback((doc) => {
    const defaultFieldsData =
      Object.keys(FIELD_TITLES).reduce((defaultFieldsType, fieldType) => {
        const defaultFields = (doc[fieldType] || [])
          .reduce((defaultField, objField) => ({ ...defaultField, [objField.field]: objField.value }), {})

        return { ...defaultFieldsType, [fieldType]: defaultFields }
      }, {});

    setSelectedFieldItems(defaultFieldsData)
  }, []);


  const handleFieldsCancel = useCallback(() => {
    const tree = prepareTree({ docsConfig, docs });
    const doc = findItem(tree, docId);
    setDefaultFieldsData(doc);
  }, [{ docsConfig, docs }]);

  const handleFieldsSave =
    useCallback(() => {
      const nextFieldsTypes = Object.keys(selectedFieldItems).reduce((result, fieldsType) => {
        const modifyItems = selectedFieldItems[fieldsType];

        return {
          ...result,
          [fieldsType]: [
            ...(doc[fieldsType] || []).map(
              (fieldObj) => modifyItems[fieldObj.field] === undefined
                ? fieldObj
                : { ...fieldObj, value: modifyItems[fieldObj.field] }
            )
          ]
        }
      }, {});
      const nextDoc = { ...doc, ...nextFieldsTypes };
      console.log('nextDoc: ', nextDoc);
      setDoc(nextDoc);

      const dbDoc = Object.keys(FIELD_TITLES).reduce((result, fieldsType) => ({
        ...result,
        ...nextDoc[fieldsType].reduce(((fieldResult, { field, value }) => ({ ...fieldResult, [field]: value })), {})
      }), parentId ? { [`${getParentType(docType)}Id`]: parentId } : {});

      console.log('docsConfig[nextDoc.type].TargetCollection: ', docsConfig[nextDoc.type].TargetCollection);
      console.log('dbDoc: ', dbDoc)


      Request.updateDoc(docsConfig[nextDoc.type].TargetCollection, nextDoc.nodeId, dbDoc)
    }, [JSON.stringify({ selectedFieldItems, doc, parentId })]);

  useEffect(() => {
    Promise.all([Request.getDocsConfig(), Request.getDocs()])
      .then(([docsConfig, docs]) => {
        setDocsConfig(docsConfig);
        setDocs(docs);

        const rawDoc = findDoc({ id: docId, docType, docs });
        const isHaveParent = haveParent({ doc: rawDoc, docType });
        const parentType = getParentType(docType);

        if (parentType) {
          setParents(docs[parentType].map(
            (parentDoc) => Object.keys(parentDoc).reduce((result, key) => ({ ...result, [key.replace(parentType, '')]: parentDoc[key] }), {}))
          );
        }

        setIsHaveParent(isHaveParent);
        if (parentType && !isHaveParent) {
          const doc = composeDoc(rawDoc, docsConfig[docType], docType);
          setDoc(doc);
          setDefaultFieldsData(doc);

          return;
        }

        const tree = prepareTree({ docsConfig, docs });
        const doc = findItem(tree, docId);
        setPageTitle(`${doc.clientName || ''} Details`);
        setDoc(doc);
        setParentId(rawDoc[`${parentType}Id`]);
        setDefaultFieldsData(doc);
        setChildDocs(getChildDocs(doc));
      });
  }, []);

  return (
    <>
      <WrapperSimple>
        <Card className="card-box mb-spacing-6-x2">
          <div className="card-footer py-3 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div className="card-header--actions">
                <Button size="small" className="btn-neutral-primary">
                  <span
                    className="btn-wrapper--label"
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                  >
                    Back
                  </span>
                </Button>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="px-4 py-3">
            {

              !!childDocs.length &&
                <Autocomplete
                  multiple
                  options={[...new Set(childDocs.map(({ type }) => type))]}
                  getOptionLabel={type => type}
                  onChange={(_, value) => setSelectedTypes(value)}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} />
                    ))
                  }
                  }
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="DocTypes"
                      variant="outlined"
                      placeholder="DocTypes"
                      fullWidth
                    />
                  )}
                />
            }
          </div>
          <div className="divider" />
          <div className="table-responsive-md">
            {
              !!childDocs.length &&
                <Table className={`table table-hover text-nowrap mt-1 ${tableResponsiveStyles.root}`}>
                  <thead>
                  <tr>
                    {dataGridSchema.map(({ label }, index) => (<th key={`${index}-th`} className="bg-white text-center">{label}</th>))}
                  </tr>
                  </thead>
                  <tbody>
                  {
                    childDocs
                      .filter(({ type }) => !selectedTypes.length || selectedTypes.includes(type))
                      .map((doc, index) => (
                          <tr key={index}>
                            {
                              dataGridSchema.map(({ key, render }, subIndex) => render
                                ? render({ doc, index, history, setRemoveDoc, toggleModal })
                                : (<td key={`${index + 1}-${subIndex}-td`} className="text-center">{doc[key]}</td>)
                              )
                            }
                          </tr>
                        )
                      )
                  }
                  </tbody>
                </Table>
            }
          </div>
          <div className="card-footer py-3 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div className="card-header--actions">
                <Button
                  size="small"
                  className="btn-neutral-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    const parentId = getParentDocId({ id: doc.nodeId, docType, docs });
                    if (parentId) {
                      history.push(`/doc-create/${docType}/${parentId}`)
                    } else {
                      history.push(`/doc-create/${docType}/`)
                    }
                  }}
                >
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                  </span>
                  <span className="btn-wrapper--label">Create New Doc</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="table-responsive-md">
            <div className="d-flex flex-wrap">
              <Grid container spacing={0}>
                <Grid item xl={12} className="pt-3">
                  {
                    !!Object.keys(doc).length && !!getParentType(docType) &&
                      <div className="w-100 p-1">
                        <div
                          variant="outlined"
                          className={`card card-box d-block text-left p-5 ${DivStyles.root}`}
                        >
                          <div className="font-weight-bold font-size-md text-lg-center w-100">
                            {TITLES.ASSIGNTO}
                          </div>
                          <div>
                            <TextField
                              fullWidth
                              select
                              variant="outlined"
                              className="mb-3"
                              id="standard-multiline-flexible"
                              key="doc-type"
                              value={parentId}
                              onChange={e => { setParentId(e.target.value) }}
                              label={getParentType(docType)}
                            >
                              {
                                parents.map(({ Id, Name }) => (<MenuItem key={Id} value={Id}>{Name}</MenuItem>))
                              }
                            </TextField>
                          </div>
                        </div>
                      </div>
                  }
                  {
                    Object.keys(FIELD_TITLES).map(fieldsType => (
                      (
                        fieldsType !== FIELD_TITLES.Aggregations && !!((doc || {})[fieldsType] || []).length ||
                        fieldsType === FIELD_TITLES.Aggregations && !!((doc || {})[fieldsType] || []).length && isShowAggregations
                      ) &&
                      <div key={fieldsType} className="w-100 p-1">
                        <div
                          variant="outlined"
                          className={`card card-box d-block text-left p-5 ${DivStyles.root}`}
                        >
                          {
                            ((doc || {})[fieldsType] || []).length
                              ? (
                                <>
                                  <div className="font-weight-bold font-size-md text-lg-center w-100">
                                    {FIELD_TITLES[fieldsType]}
                                  </div>

                                  <div>
                                    {
                                      doc[fieldsType].map(item =>
                                        <RenderField
                                          {
                                            ...{
                                              item,
                                              fieldsType,
                                              selectedFieldItems,
                                              setSelectedFieldItems,
                                              handleGridChange,
                                              docType
                                            }
                                          }
                                        />
                                      )
                                    }
                                  </div>
                                </>
                              )
                              : (
                                <div className="font-weight-bold font-size-md text-lg-center">
                                  {FIELD_TITLES[fieldsType]}
                                </div>
                              )
                          }
                        </div>
                      </div>
                    ))
                  }
                </Grid>
              </Grid>
              <div className="float-right w-100">
                <div className="p-2 text-right">
                  <Button
                    onClick={() => handleFieldsCancel()}
                    className="btn-neutral-secondary text-danger">
                    <span className="btn-wrapper--label">Cancel</span>
                  </Button>
                  <Button onClick={() => handleFieldsSave()} className="btn-primary m-2">
                    <span className="btn-wrapper--label">Save</span>
                  </Button>
                </div>
                <div className="p-4 text-right">
                  <div className="card-header--actions">
                    {
                      getChildTypes(doc.type) &&
                      <Button
                        size="small"
                        className="btn-neutral-primary"
                        onClick={(e) => {
                          e.preventDefault()
                          history.push(`/doc-create/child-doc/${doc.nodeId}`)
                        }}
                      >
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                        </span>
                        <span className="btn-wrapper--label">Create New Child Doc</span>
                      </Button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

        </Card>
      </WrapperSimple>
      <Dialog open={openModal} onClose={toggleModal} classes={{ paper: 'shadow-lg rounded' }}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon icon={['fas', 'times']} className="d-flex align-self-center display-3" />
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">Are you sure you want to delete this document?</h4>
          <p className="mb-0 font-size-lg text-muted">You cannot undo this operation.</p>
          <div className="pt-4">
            <Button onClick={toggleModal} className="btn-neutral-secondary btn-pill mx-1">
              <span className="btn-wrapper--label"> Cancel </span>
            </Button>
            <Button
              onClick={() => {
                Request.removeDoc(docsConfig[removeDoc.type].TargetCollection, removeDoc.nodeId).then(data => {
                  console.log('data: ', data);
                  setRemoveDoc(undefined)
                  toggleModal();
                  history.push(`/`);
                })
              }}
              className="btn-danger mx-1"
            >
              <span className="btn-wrapper--label"> Delete </span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  pageTitle: state.ThemeOptions.pageTitle
});

const mapDispatchToProps = (dispatch) => ({
  setPageTitle: (pageTitle) => dispatch(setPageTitle(pageTitle))
});

export default connect(mapStateToProps, mapDispatchToProps)(DocDetails);
