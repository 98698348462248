import { getAxios } from './../../utils/axios';

export const getMenus = () =>
  getAxios().get('/menus');

export const getSystemFields = () =>
  getAxios().get('/pf-admin/settings/1.0.0');

export const updateSystemFields = ({ _id, ...data }) =>
  getAxios().put('/pf-admin/settings/1.0.0', data);

