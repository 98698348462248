import React from 'react';
import { Table } from '@material-ui/core';
import { THEME_SIZES } from '../../../constants';
import { round } from '../../../utils/common'
import { useTableResponsiveStyles } from '../../../theme/styles';

export default (props) => {
  const { label, calculations } = props;

  if (!calculations) return <></>;

  const tableResponsiveStyles = useTableResponsiveStyles({
    columns: Object.keys(calculations || []),
    breakpoint: THEME_SIZES.xl
  });

  return (
    <div className="m-2">
      <div className={`card-header--title font-weight-bold font-size-lg text-sm-left`}>
        {label}
      </div>
      <Table className={`w-50 table table-hover table-bordered text-nowrap mt-1 ${tableResponsiveStyles.root}`}>
        <thead>
        <tr>
          {
            Object.keys(calculations).map((key, index) =>
              (<th key={`${index}-th`} className="text-center">{key}</th>)
            )
          }
        </tr>
        </thead>
        <tbody>
        <tr>
          {
            Object.values(calculations).map((val, index) =>
              (<td key={`${index}-td`} className="text-center">{round(val)}</td>)
            )
          }
        </tr>
        </tbody>
      </Table>
    </div>
  );
}
