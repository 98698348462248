import PropTypes from 'prop-types';
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import PriorityHighTwoToneIcon from '@material-ui/icons/PriorityHighTwoTone';
import { useTreeItemStyles } from './styles';
import { Request } from '../../../services';
import { getParentDocId } from '../../../utils/common';

export const StyledTreeItem = (props) => {
  const history = useHistory();
  const classes = useTreeItemStyles();
  const {
    editItems = {},
    itemClickAction = () => {},
    TreeItem,
    clientName,
    labelInfo,
    color,
    bgColor,
    editBtn,
    children = [],
    nodeId,
    type,
    notification,
    ...other
  } = props;
  const [editclientName, setEditclientName] = useState(clientName);
  const [editlabelInfo, setEditlabelInfo] = useState(labelInfo);

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          {
            notification &&
              <PriorityHighTwoToneIcon style={{ fill: 'red', fontSize: '15px', marginBottom: '4px' }} />
          }
          {
            !editItems[props.nodeId]
              ? (<Typography variant="body2" className={classes.clientName}>{clientName}</Typography>)
              : (
                <TextField
                  id="standard-basic"
                  value={editclientName}
                  onChange={e => {  setEditclientName(e.target.value) }}
                  onKeyPress={e =>
                    (e.key === 'Enter') &&
                      itemClickAction({ id: props.nodeId, action: 'save', clientName: editclientName, labelInfo: editlabelInfo })
                  }
                  onClick={e => e.stopPropagation() }
                />
              )
          }
          {
            !(editItems[props.nodeId] && labelInfo)
              ? (<Typography variant="caption" color="inherit" style={{ marginRight: '15px' }}>{labelInfo}</Typography>)
              : (
                <TextField
                  id="standard-basic"
                  value={editlabelInfo}
                  onChange={e => {  setEditlabelInfo(e.target.value) }}
                  onKeyPress={e =>
                    (e.key === 'Enter') &&
                    itemClickAction({ id: props.nodeId, action: 'save', clientName: editclientName, labelInfo: editlabelInfo })
                  }
                  onClick={e => e.stopPropagation() }
                />
              )
          }
        </div>
      }
      onLabelClick={e => e.preventDefault()}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...{ nodeId, children }}
    />
  );
};

StyledTreeItem.propTypes = {
  TreeItem: PropTypes.elementType.isRequired,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelInfo: PropTypes.string
};


export default StyledTreeItem;
