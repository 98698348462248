import { TreeItem } from '@material-ui/lab';
import React from 'react';
import { StyledTreeItem } from './../';

const RenderTreeItem = ({ item, props, docs }) => {
  const { children, icon, ...itemProperties } = item;

  if (!item.children) return(
    <StyledTreeItem
      TreeItem={TreeItem}
      key={item.nodeId}
      { ...itemProperties }
      { ...props }
    />
  );

  return (
    <StyledTreeItem
      TreeItem={TreeItem}
      key={item.nodeId}
      docs={docs}
      { ...itemProperties }
      { ...props }
    >
      {
        children.map(item => RenderTreeItem({ item, props, docs }))
      }
    </StyledTreeItem>
  )
};

export default RenderTreeItem
