import clsx from 'clsx';
import fastDeepEqual from 'fast-deep-equal';
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Button,
  Dialog,
  CircularProgress,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  List,
  ListItem,
  Grid,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem, TextField
} from '@material-ui/core';
import { TreeView } from '@material-ui/lab';
import {
  Close as CloseIcon,
  Dehaze as DehazeIcon
} from '@material-ui/icons';

import {
  DISPLAY_CONTROLLERS,
  DOC_TYPES,
  FIELD_TITLES,
  TITLES,
  ACTIONS
} from '../../../constants';

import { createDispatches } from './../../../reducers/functions';

import { Request } from '../../../services';
import {
  calculateGridData,
  findItem,
  prepareTree,
  findDoc,
  getTotalByGridValues,
  composeGridValues,
  getParentDocId,
  getChildTypes,
  getParentType,
  getParentTypesByDocs,
  getIdsTopParents,
  getExpandIds,
  modifyItems,
  AddItem,
  prepareDbDoc,
  generateId
} from '../../../utils/common';

import { useTreeStyles, useHideBorderStyles, useTableBlockStyles } from './../../../assets/styles';
import {
  useSpanHeaderStyles,
  useFieldsBlockStyles,
  useDialogStyles
} from './styles';
import {
  MinusSquare,
  PlusSquare,
  CloseSquare
} from './../../icons'

import HeaderSearch from './../../layout-components/HeaderSearch';
import { GridDetails, RenderField, RenderTreeItem } from './../../controls';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    margin: '10px !important',
    height: '100px !important',
    width: '100px !important',
  }
}))(CircularProgress);

const FIELD_TYPES = Object.keys(FIELD_TITLES);

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const UserDashboard = () => {
  const { setPageButtonsProps } = createDispatches(useDispatch());
  const pageButtonsProps = useSelector(state => state.ThemeOptions.pageButtonsProps);

  const [docsConfig, setDocsConfig] = useState();
  const [controlDocsConfig, setControlDocsConfig] = useState([]);
  const [docs, setDocs] = useState({});
  const [controlDocs, setControlDocs] = useState({});
  const [editItems, setEditItems] = useState({});
  const [items, setItems] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [selected, setSelected] = useState('');
  const [selectedFieldItems, setSelectedFieldItems] = useState({});
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  const [openModal, setOpenModal] = useState(false);
  const [openGridModal, setOpenGridModal] = useState(false);
  const [openGridGroupModal, setOpenGridGroupModal] = useState(false);
  const [removeFunc, setRemoveFunc] = useState(() => {});
  const [isDataLoad, setIsDataLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(FIELD_TITLES.Fields);
  const [openActions, setOpenActions] = useState(false);
  const [parentId, setParentId] = useState('');
  const [search, setSearch] = useState();
  const [parents, setParents] = useState([]);

  const anchorRef = useRef(null);

  const tableBlockStyles = useTableBlockStyles();
  const classes = useTreeStyles();
  const SpanHeaderStyles = useSpanHeaderStyles();
  const DivStyles = useHideBorderStyles();
  const FieldsBlockStyles = useFieldsBlockStyles({ windowDimensions });
  const DialogStyles = useDialogStyles();

  const selectedItem = findItem(items, selected);
  const isShowAggregations = (selectedItem?.[FIELD_TITLES.Aggregations] || []).every(({ value }) => !!value);

  const { type: docType } = selectedItem || {};

  const initTree = ({ init = false, action } = {}) => {
    Promise.all([Request.getDocsConfig(null), Request.getDocs()])
      .then(([allDocsConfig, docs]) => {

        const docsConfig = allDocsConfig[DOC_TYPES.xDocs];
        setControlDocsConfig(allDocsConfig[DOC_TYPES.ControlDocs]);

        const tree = prepareTree({ docsConfig, docs });
        setItems(tree);
        setDocsConfig(docsConfig);
        setDocs(docs);

        const [{ nodeId } = {}] = tree || [];
        setSelected(action !== ACTIONS.Remove && selectedItem ? selectedItem.nodeId : nodeId);
        if (init) setExpanded([]);
        setIsDataLoad(true);
        console.log('initTree done!')
      });
  };

  const defaultValues = findDoc({ id: selectedItem?.nodeId, docType, docs }) || {};
  const childTypes = (getChildTypes(docType) || []).map(docType => ({ docType, title: docsConfig[docType]?.DocumentType }));

  console.log('selectedDbItem: ', defaultValues);
  useEffect(() => {
    if (!selectedFieldItems?.[FIELD_TITLES.Fields]?.[`${docType}Name`] || !selected) return;

    const nextItems = modifyItems(items, {
      id: selected,
      clientName: `${docsConfig[docType].DocumentType} - ${selectedFieldItems?.[FIELD_TITLES.Fields]?.[`${docType}Name`]}`
    });

    setItems(nextItems);
  }, [selectedFieldItems?.[FIELD_TITLES.Fields]?.[`${docType}Name`]]);

  useEffect(() => {
    if (typeof search === 'undefined') return;

    const regex = new RegExp(search, 'ig');
    const searchDocs = Object.keys(docs).reduce((result, docType) => {
      return [
        ...result,
        ...docs[docType]
          .filter(({ [`${docType}Name`]: name = '' }) => search ? regex.test(name) : true)
          .map((doc) => ({ doc, docType }))
      ]
    }, []);

    const topParents = getIdsTopParents({ searchDocs, docs });

    const topParentType = getParentTypesByDocs(docs);
    const nextDocs =
      Object.keys(docs).reduce((result, docType) => {
          const isParentDocType = topParentType.includes(docType);

          const nextDocs = isParentDocType
            ? docs[docType].filter(typeDoc => topParents.includes(typeDoc[`${docType}Id`]))
            : docs[docType]
          ;
          return { ...result, [docType]: topParents.length ? nextDocs : [] }
        }, {});

    const tree = prepareTree({ docsConfig, docs: nextDocs });
    setItems(tree);
    const [{ nodeId } = {}] = tree || [];
    setSelected(nodeId);
    setExpanded(!search ? [] : getExpandIds(tree));

  }, [search]);

  useEffect(() => {
    const { TimeFields } = selectedFieldItems || {};
    const docConfig = docsConfig?.[docType];
    const data = calculateGridData({ docType, defaultValues, TimeFields, docConfig });

    if (!data) return;

    setSelectedFieldItems({
      ...selectedFieldItems,
      [FIELD_TITLES.TimeFields]: {
        ...selectedFieldItems[FIELD_TITLES.TimeFields],
        [`${docType}GridValues`]: data.gridValues,
        [`${docType}Total`]: data.total,
        ...(
          !selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}StartDate`]
            ? { [`${docType}StartDate`]: new Date() }
            : {}
        )
      }
    });
  }, [
    selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}PerPeriod`],
    selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}PeriodType`],
    selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}NumberOfYears`],
    selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}StartDate`]
  ]);

  const toggleTab = useCallback((tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  }, [activeTab]);

  const handleGridChange = useCallback((year, index, val) => {
    if (!selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}GridValues`]) return;

    const nextDocGridValues = composeGridValues({
      gridValues: selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}GridValues`],
      year,
      index,
      val
    });

    setSelectedFieldItems({
      ...selectedFieldItems,
      [FIELD_TITLES.TimeFields]: {
        ...selectedFieldItems[FIELD_TITLES.TimeFields],
        [`${docType}GridValues`]: nextDocGridValues,
        [`${docType}Total`]: getTotalByGridValues(nextDocGridValues)
      }
    });
  }, [selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}GridValues`]]);

  const toggleGridModal = useCallback(() => {
    setOpenGridModal(!openGridModal);
  }, [openGridModal]);

  const handleToggleActions = useCallback(() => {
    setOpenActions(!openActions);
  }, [openActions]);

  const toggleGridGroupModal = useCallback(() => {
    setOpenGridGroupModal(!openGridGroupModal);
  }, [openGridGroupModal]);

  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal, removeFunc]);

  const createRemoveFunc = (id, type) => () => () => {
    toggleModal();

    return Request.removeDoc(docsConfig[type].TargetCollection, id)
      .then(data => {
          initTree({ action: ACTIONS.Remove });
      });
  };

  const treeProps = {
    editItems,
    itemClickAction: ({ id, type, action, clientName, labelInfo }) => {
      const selectedAction = {
        edit: () => setEditItems({ ...editItems, [id]: true }),
        save: () => {
          setItems(modifyItems(items, { id, clientName, labelInfo }))
          setEditItems({...editItems, [id]: false})
        },
        delete: () => {
          toggleModal();
          setRemoveFunc(createRemoveFunc(id, type));
        },
        add: () => {
          setItems(AddItem(items, id));
          // setEditItems({ ...editItems, [createdId]: true });
          setExpanded([...expanded, id])
        }
      }[action];

      if (selectedAction) selectedAction();
    }
  };
  const handleToggle = useCallback((event, nodeIds) => {
    setExpanded(nodeIds);
  }, []);
  const handleSelect = useCallback((event, nodeId) => {
    setSelected(nodeId);
  }, []);

  const handleFieldsSave =
    useCallback( () => {
      const dbDoc = prepareDbDoc({ selectedItem, selectedFieldItems, parentId, docType });
      console.log('docsConfig[nextDoc.type].TargetCollection: ', docsConfig[docType].TargetCollection);
      console.log('dbDoc: ', dbDoc);

      const { method, args } = [
        {
          condition: selectedItem.isNew,
          method: 'createDoc',
          args: [docsConfig[docType].TargetCollection, { ...dbDoc, [`${docType}Id`]: selectedItem.nodeId }]
        },
        {
          condition: !selectedItem.isNew,
          method: 'updateDoc',
          args: [docsConfig[docType].TargetCollection, selectedItem.nodeId, dbDoc]
        }
      ].find(({ condition }) => condition);

      Request[method](...args)
        .then(() => {
          initTree();
          setOpenGridModal(false);
        });
      // setItems(modifyItems(items, nextSelectedItem));

    }, [JSON.stringify({ selectedFieldItems }), parentId]);

  const setDefaultFieldsData = useCallback(() => {
    if (!selectedItem) return;

    const defaultFieldsData =
      FIELD_TYPES.reduce((defaultFieldsType, fieldType) => {
        const defaultFields = (selectedItem[fieldType] || [])
          .reduce((defaultField, objField) => ({ ...defaultField, [objField.field]: objField.value }), {})

        return { ...defaultFieldsType, [fieldType]: defaultFields }
      }, {});

    setSelectedFieldItems(defaultFieldsData)
  }, [JSON.stringify(selectedItem)]);

  const handleFieldsCancel = useCallback( () => {
    const tree = prepareTree({ docsConfig, docs });
    setItems(tree);
    const parentType = getParentType(docType);
    if (parentType) {
      setParents(docs[parentType].map(
        (parentDoc) => Object.keys(parentDoc).reduce((result, key) => ({ ...result, [key.replace(parentType, '')]: parentDoc[key] }), {}))
      );
      setParentId(getParentDocId({ id: selectedItem.nodeId, docType, docs }));
    }
    setDefaultFieldsData();
  }, [JSON.stringify({ selectedFieldItems }), parentId]);

  useEffect( () => {
    if (!selectedItem) return;

    const { [`${docType}Id`]: id } = defaultValues;
    const dbDoc = prepareDbDoc({ selectedItem, selectedFieldItems, parentId, docType, docsConfig  });

    setPageButtonsProps({
      isShowEditBtns: !fastDeepEqual(dbDoc, defaultValues),
      selectedItem,
      childTypes,
      selectedDoc: { parentId, title: docsConfig[docType]?.DocumentType, docType },
      onAdd: (addDocType) => {
        const isChild = addDocType !== docType;
        const prId = isChild ? id : parentId;
        const generatedId = generateId(addDocType);

        const nextItems = AddItem({
          parentId: prId,
          docType: addDocType,
          items,
          generatedId,
          docConfig: docsConfig[addDocType]
        });
        setItems(nextItems);

        const parentType = getParentType(addDocType);
        const doc = { [`${addDocType}Id`]: generatedId, ...(parentType ? { [`${parentType}Id`]: prId } : {}) };
        setDocs({ ...docs, [addDocType]: [...docs[addDocType], doc] });

        Request.createDoc(
          docsConfig[addDocType].TargetCollection,
          { [`${addDocType}Id`]: generatedId, ...(parentType ? { [`${parentType}Id`]: prId } : {}) }
        ).then(() => {});

        setParentId(prId);
        setSelectedFieldItems({});
        setSelected(generatedId);
        setExpanded([...expanded, prId]);
      },
      handleFieldsCancel,
      handleFieldsSave
    });
  },[defaultValues, selectedFieldItems, parentId, items]);

  useEffect(() => initTree({ init: true }), []);

  useEffect( () => {
    Object.keys(controlDocsConfig).forEach(ctrlDocType => {
      controlDocsConfig[ctrlDocType].Fields.forEach(field => {
        if (
          field.DisplayController === DISPLAY_CONTROLLERS.RecordSet &&
            field.SourceAtributes.Collection === selectedItem?.collection
        ) {
          if (defaultValues[`${ctrlDocType}Id`]) {
            Request.getControlDocs({ docType: ctrlDocType, [`${ctrlDocType}Id`]: defaultValues[`${ctrlDocType}Id`] })
              .then(data => {
                console.log('data: ', data)
                setControlDocs({
                  ...controlDocs,
                  [selectedItem.type]: {
                    ...controlDocs[ctrlDocType],
                    [ctrlDocType]: {
                      TargetCollection: controlDocsConfig[ctrlDocType].TargetCollection,
                      data
                    }
                  }
                });
              });
          }
        }
      });
    });
  }, [JSON.stringify(selectedItem, defaultValues)]);

  useEffect(() => {
    setDefaultFieldsData();
    toggleTab(FIELD_TITLES.Fields);

    const parentType = getParentType(docType);

    if (parentType) {
      setParents(docs[parentType].map(
        (parentDoc) => Object.keys(parentDoc).reduce((result, key) => ({ ...result, [key.replace(parentType, '')]: parentDoc[key] }), {}))
      );
      setParentId(getParentDocId({ id: selectedItem.nodeId, docType, docs }));
    } else {
      setParents([]);
      setParentId();
    }
  }, [selectedItem?.nodeId]);

  useEffect(() => {
    const handleResize = () => setWindowDimensions(getWindowDimensions());
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Card className={`shadow-xxl ${tableBlockStyles.rootElement}`}>
        {
          !isDataLoad && <BorderLinearProgress className="progress-xs" color="primary" />
        }
        {
          isDataLoad &&
            <CardContent className={`scroll-bar px-2 py-2 ${tableBlockStyles.navCellElement}`}>
              <HeaderSearch
                {...{ setSearch }}
              />
              <TreeView
                className={classes.root}
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}
                expanded={expanded}
                selected={selected}
                onNodeToggle={handleToggle}
                onNodeSelect={handleSelect}
              >
                {
                  items.map(item =>
                    <RenderTreeItem
                      key={item.nodeId}
                      {
                        ...{
                          item,
                          treeProps,
                          docs
                        }
                      }
                    />
                  )
                }
              </TreeView>
          </CardContent>
        }
        <CardContent className={`text-right ${FieldsBlockStyles.right} ${tableBlockStyles.cellElement}`}>
          {
            isDataLoad &&
              <div className="w-100" style={{ background: '#fdeab2' }}>
                <span className="float-left font-weight-bold font-size-lg" style={{ paddingLeft: '2.5rem', marginTop: '10px' }}>
                  { selectedItem ? (<><span className={SpanHeaderStyles.root}>Document:</span> {selectedItem.clientName}</>) : ''}
                </span>
                <span className="text-right">
                  <IconButton
                    ref={anchorRef}
                    aria-controls={openActions ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggleActions}
                  >
                    <DehazeIcon />
                  </IconButton>
                  <Popper open={openActions} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement={'bottom-end'} style={{ zIndex: 10}} modifiers={{ offset: {  offset: -10 } }}>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={() => { setOpenActions(false) }}>
                            <MenuList autoFocusItem={openActions} id="menu-list-grow" >
                              <MenuItem
                                onClick={(event) => {
                                  event.preventDefault();
                                  pageButtonsProps.onAdd(pageButtonsProps.selectedDoc.docType);
                                }}
                              >
                                Create {docsConfig[docType]?.DocumentType}
                              </MenuItem>
                              {
                                (childTypes || []).map(({ docType, title }) => (
                                  <MenuItem
                                    key={docType}
                                    onClick={(event) => {
                                      event.preventDefault();
                                      pageButtonsProps.onAdd(docType);
                                    }}
                                  >
                                    Create {title}
                                  </MenuItem>
                                ))
                              }
                              <MenuItem
                                className="border-top"
                                onClick={(e) => {
                                  e.preventDefault();
                                  toggleModal();
                                  setRemoveFunc(createRemoveFunc(selectedItem.nodeId, docType));
                                }}
                              >
                                Remove Doc
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </span>
              </div>
          }
          {
            isDataLoad &&
              <List className="nav-tabs nav-tabs d-flex justify-content-left w-100" style={{ background: '#fdeab2' }}>
                {
                  FIELD_TYPES.map((fieldsType) => (
                    (fieldsType !== FIELD_TITLES.Aggregations && !!((selectedItem || {})[fieldsType] || []).length ||
                      !!((selectedItem || {})[fieldsType] || []).length && fieldsType === FIELD_TITLES.Aggregations && isShowAggregations) &&
                        <ListItem
                          key={fieldsType}
                          style={{ borderRadius: 0 }}
                          button
                          disableRipple
                          selected={activeTab === fieldsType}
                          onClick={() => {
                            toggleTab(fieldsType);
                          }}
                        >
                          <span className="font-weight-bold text-uppercase font-size-sm">
                            {FIELD_TITLES[fieldsType]}
                          </span>
                        </ListItem>
                  ))
                }
                {
                  !!parents.length &&
                    <ListItem
                      key={TITLES.ASSIGNTO}
                      style={{ borderRadius: 0 }}
                      button
                      disableRipple
                      selected={activeTab === TITLES.ASSIGNTO}
                      onClick={() => {
                        toggleTab(TITLES.ASSIGNTO);
                      }}
                    >
                      <span className="font-weight-bold text-uppercase font-size-sm">
                        {TITLES.ASSIGNTO}
                      </span>
                    </ListItem>

                }
              </List>
          }
          {
            FIELD_TYPES.map((fieldsType) => (
              (fieldsType !== FIELD_TITLES.Aggregations && !!((selectedItem || {})[fieldsType] || []).length ||
                !!((selectedItem || {})[fieldsType] || []).length && fieldsType === FIELD_TITLES.Aggregations && isShowAggregations) &&
                  <div
                    key={fieldsType}
                    className={clsx('tab-item-wrapper w-100', {
                      active: activeTab === fieldsType
                    })}
                    index={fieldsType}
                  >
                    <div className="d-flex flex-wrap">
                      <div key={fieldsType} className="w-100 p-1">
                        <div
                          variant="outlined"
                          className={`d-block text-left p-5 ${DivStyles.root}`}
                        >
                        {
                          <>
                            {
                              ![FIELD_TITLES.Aggregations, FIELD_TITLES.Calculations].includes(fieldsType) &&
                                <Grid container spacing={1}>
                                  {
                                    selectedItem[fieldsType].map(item =>
                                      <Grid item xl={3} lg={6} md={12} key={item.field || item.label}>
                                        <RenderField
                                          {
                                            ...{
                                              item,
                                              fieldsType,
                                              selectedFieldItems,
                                              setSelectedFieldItems,
                                              toggleGridModal:
                                                !!getTotalByGridValues((selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}GridValues`]) || {}) &&
                                                toggleGridModal,
                                              // toggleGridGroupModal,
                                              docType
                                            }
                                          }
                                        />
                                      </Grid>
                                    )
                                  }
                                </Grid>
                            }
                            {
                              [FIELD_TITLES.Aggregations, FIELD_TITLES.Calculations].includes(fieldsType) &&
                                selectedItem[fieldsType].map(item => (
                                  <RenderField
                                    key={item.field}
                                    {
                                      ...{
                                        item,
                                        fieldsType,
                                        selectedFieldItems,
                                        setSelectedFieldItems,
                                        docType
                                      }
                                    }
                                  />
                                )
                              )
                            }
                            {
                              fieldsType === FIELD_TITLES.TimeFields && controlDocs[selectedItem.type] &&
                                <div className="mb-3 mt-3">
                                  {
                                    Object.keys(controlDocs[selectedItem.type]).map(ctrlType => (
                                      !!controlDocs[selectedItem.type][ctrlType].data.length &&
                                      <React.Fragment key={ctrlType}>
                                        <div className="font-weight-bold font-size-lg mb-1 text-black">
                                          {controlDocs[selectedItem.type][ctrlType].TargetCollection}
                                        </div>
                                        {
                                          controlDocs[selectedItem.type][ctrlType].data.map(ctrlDoc => {
                                            return (
                                              <Link key={ctrlDoc[`${ctrlType}Id`]} className="mr-2" href={`/control-docs/${ctrlType}/${ctrlDoc[`${ctrlType}Id`]}`} variant="body2">
                                                { ctrlDoc[`${ctrlType}Name`] }
                                              </Link>
                                            )
                                          })
                                        }
                                      </React.Fragment>
                                    ))
                                  }
                                </div>
                            }
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
            ))
          }
          {
            !!parents.length &&
              <div
                className={clsx('tab-item-wrapper w-100', {
                  active: activeTab === TITLES.ASSIGNTO
                })}
                index={TITLES.ASSIGNTO}
              >
                <div className="d-flex flex-wrap">
                  <div className="w-100 p-1">
                    <div
                      variant="outlined"
                      className={`d-block text-left p-5 ${DivStyles.root}`}
                    >
                      <Grid container spacing={1}>
                        <Grid item xl={3} lg={6} md={12}>
                          <TextField
                            fullWidth
                            select
                            variant="outlined"
                            className="mb-3"
                            id="standard-multiline-flexible"
                            key="doc-type"
                            value={parentId}
                            onChange={e => { setParentId(e.target.value) }}
                            label={getParentType(docType)}
                          >
                            {
                              parents.map(({ Id, Name }) => (<MenuItem key={Id} value={Id}>{Name}</MenuItem>))
                            }
                          </TextField>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
          }
        </CardContent>
      </Card>
      <Dialog open={openModal} onClose={toggleModal} classes={{ paper: 'shadow-lg rounded' }}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon icon={['fas', 'times']} className="d-flex align-self-center display-3"/>
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">Are you sure you want to delete this document?</h4>
          <p className="mb-0 font-size-lg text-muted">You cannot undo this operation.</p>
          <div className="pt-4">
            <Button onClick={toggleModal} className="btn-neutral-secondary btn-pill mx-1">
              <span className="btn-wrapper--label"> Cancel </span>
            </Button>
            <Button
              onClick={() => { removeFunc(); toggleModal(); } }
              className="btn-danger mx-1"
            >
              <span className="btn-wrapper--label"> Delete </span>
            </Button>
          </div>
        </div>
      </Dialog>
      {
        !!getTotalByGridValues((selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${docType}GridValues`]) || {}) &&
          <Dialog
            key="grid-data-dialog"
            scroll="body"
            fullWidth={true}
            maxWidth={false}
            open={openGridModal}
            onClose={toggleGridModal}
            classes={{
              paper: 'modal-content border-0 bg-white rounded-lg p-3 p-xl-0'
            }}
          >
            <DialogTitle disableTypography className={DialogStyles.root}>
              <Typography id="form-dialog-title" className="text-lg-center">
                { selectedItem ? (<><span className={SpanHeaderStyles.root}>Document:</span> {selectedItem.clientName}</>) : ''}
              </Typography>
              <IconButton aria-label="close" className={DialogStyles.closeButton} onClick={toggleGridModal}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <GridDetails
                selectedFieldItems={selectedFieldItems}
                handleGridChange={handleGridChange}
                fieldsType={FIELD_TITLES.TimeFields}
                docType={docType}
              />
              <div className="float-right font-weight-bold pr-3 pb-3">
                Total: { getTotalByGridValues(selectedFieldItems[FIELD_TITLES.TimeFields][`${docType}GridValues`]) }
              </div>
            </DialogContent>
            <DialogActions className="p-4 w-100">
              <Button
                onClick={() => {
                  handleFieldsCancel();
                  toggleGridModal();
                }}
                className="btn-neutral-secondary text-danger">
                <span className="btn-wrapper--label">Cancel</span>
              </Button>
              <Button onClick={handleFieldsSave} className="btn-primary m-2">
                <span className="btn-wrapper--label">Save</span>
              </Button>
            </DialogActions>
          </Dialog>

      }
    </>
  );
}

export default UserDashboard;
