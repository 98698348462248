import { set as setByDot, delete as deleteByDot } from 'dot-prop';
import React, { useState, useEffect, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  InputLabel,
  InputAdornment,
  Card,
  MenuItem,
  Button,
  Tooltip,
  TextField,
  FormControl,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  makeStyles,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Box,
  Collapse
} from '@material-ui/core';

import {
  Close as CloseIcon,
  AddTwoTone as AddTwoToneIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon
} from '@material-ui/icons';
import { Request } from '../../../services';
import { DOC_HIERARCHY, FIELD_TYPES, OBJECT_FIELD_TYPES, ACTIONS } from '../../../constants';
import { useDialogStyles } from './styles'


const allDocTypes = [...new Set(Object.keys(DOC_HIERARCHY).reduce((result, docType) => {
  return [
    ...result,
    docType,
    ...DOC_HIERARCHY[docType]
  ]
}, []))];

const isObject = (data) => !Array.isArray(data) && typeof data === 'object';
const maxDepth = (obj) => {
  if (typeof obj !== "object" || obj === null) {
    return 0;
  }

  const values = Object.values(obj);

  return (values.length && Math.max(...values.map(value => maxDepth(value)))) + 1;
};

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});
const renderRow = ({ systemField, field, values, currentValues, parentKey='', keys = [], openColapse, setOpenColapse, classes, handleEdit }) => {
  const hasChildren = maxDepth(currentValues) > 1;
  const getOpenKey = key => `${key} - ${keys.filter(k => k !== key).join(';')}`;

  const renderTableRow = (keyName) => (
    <React.Fragment key={getOpenKey(keyName)}>
      <TableRow key={getOpenKey(keyName)} className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={
              () => {
                setOpenColapse({ ...openColapse, [getOpenKey(keyName)]: !openColapse[getOpenKey(keyName)] })
                console.log('openColapse: ', openColapse)
              }
            }>
            {openColapse[getOpenKey(keyName)] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="row" className="text-center">
          {keyName}
        </TableCell>
        <TableCell scope="row">
          <Button
            className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={() => {
              console.log('keys: ', keys)
              console.log('field: ', field)

              handleEdit({ action: ACTIONS.AddKey, systemField, field, values, keys: [...keys, keyName] })
            }}
          >
            <FontAwesomeIcon
              icon={['fas', 'plus']}
              className="font-size-sm"
            />
          </Button>
          <Button
            className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={() => handleEdit({ action: ACTIONS.EditKey, systemField, field, values, keys: [...keys, keyName] })}
          >
            <FontAwesomeIcon
              icon={['far', 'edit']}
              className="font-size-sm"
            />
          </Button>
          <Button
            className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={() => handleEdit({ action: ACTIONS.RemoveKey, systemField, field, values, keys: [...keys, keyName], keyName })}
          >
            <FontAwesomeIcon
              icon={['fas', 'times']}
              className="font-size-sm"
            />
          </Button>
        </TableCell>
      </TableRow>
      {
        renderRow({ systemField, field, handleEdit, values, currentValues: currentValues[keyName], parentKey: keyName, keys: [...keys, keyName], openColapse, setOpenColapse, classes })
      }
    </React.Fragment>
  )

  return hasChildren
    ? Object.keys(currentValues).map(keyName =>
        keys.length
          ? (
            <TableRow key={getOpenKey(keyName)}>
              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
                <Collapse in={openColapse[getOpenKey(parentKey)]} timeout="auto" unmountOnExit>
                  <Box margin={1}>
                    <Table size="small" aria-label="purchases">
                      <TableBody>
                        { renderTableRow(keyName) }
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          )
          : renderTableRow(keyName)
      )
    : (
      <TableRow key={keys.join(',')}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={ Object.keys(currentValues).length}>
          <Collapse in={openColapse[getOpenKey(parentKey)]} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {
                      Object.keys(currentValues).map(keyName => <TableCell className="text-center">{keyName}</TableCell>)
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                  {
                    Object.values(currentValues).map(value => <TableCell className="text-center">{value}</TableCell>)
                  }
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    )
}

const renderTableField = ({ systemField, field, values, handleEdit, openColapse, setOpenColapse, classes }) => {

  const val = (values || [])[0];
  const isValObject = val && typeof val === 'object';
  const dataGridSchema = [
    ...(
      !isValObject
        ? [{ label: '#', render: (_, i) => (<td key={i+1} className="text-center">{i+1}</td>) }, { label: field, key: field }]
        : Object.keys(val).map(key => ({ key, label: key }))
    ),
    {
      label: 'Actions',
      key: '',
      render: (value, i) => (
        <td key="actions" className="text-center">
          <Button
            className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={() => handleEdit({ action: ACTIONS.Edit, systemField, field, values, value, isValObject })}
          >
            <FontAwesomeIcon
              icon={['far', 'edit']}
              className="font-size-sm"
            />
          </Button>
          <Button
            className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
            onClick={() => handleEdit({ action: ACTIONS.Remove, systemField, field, values, value, isValObject })}
          >
            <FontAwesomeIcon
              icon={['fas', 'times']}
              className="font-size-sm"
            />
          </Button>
        </td>
      )
    }
  ];

  return (
    <div key={field} className="position-relative px-5 py-3 mt-3" style={{ minWidth: '450px' }}>
      {/*<div className="divider-v divider-v-lg" />*/}
      {
        isValObject && (<div className="font-size-lg mb-2">Custom { field }</div>)
      }
      <Table className="table table-hover text-nowrap mb-0">
        <thead>
        <tr>
          { dataGridSchema.map(({ label }, index) => (<th key={`${index}-th`} className="bg-white text-center text-capitalize">{label}</th>)) }
        </tr>
        </thead>
        <tbody>
        {
          Array.isArray(values) && values.map((value, index) => (
              <tr key={index}>
                {
                  dataGridSchema.map(({ key, render }, subIndex) => render
                    ? render(value, index)
                    : (<td key={`${index+1}-${subIndex}-td`} className="text-center">{isValObject ? value[key] : value}</td>)
                  )
                }
              </tr>
            )
          )
        }
        {
          isObject(values) && renderRow({ systemField, field, values, currentValues: values, keys: [], openColapse, setOpenColapse, classes, handleEdit })
        }
        </tbody>
      </Table>
      <div className="py-3">
        {/*<div className="card-header--title font-size-lg"></div>*/}
        <div className="float-right">
          <Button
            size="small"
            className="btn-neutral-primary"
            onClick={() => handleEdit({
              action: Array.isArray(values) ? ACTIONS.Add : ACTIONS.AddKey,
              systemField,
              field,
              values,
              isValObject
            })}
          >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'plus-circle']} />
              </span>
            <span className="btn-wrapper--label">Add {field}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default (props) => {
  const [systemFields, setSystemFields] = useState({});
  const [selectedField, setSelectedField] = useState({});
  const [selectedParams, setSelectedParams] = useState({});
  const [selectedAddField, setSelectedAddField] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openAddFieldModal, setOpenAddFieldModal] = useState(false);

  const [openColapse, setOpenColapse] = useState({});
  const classes = useRowStyles();

  const DialogStyles = useDialogStyles();

  const handleAddField = useCallback((systemField) => {
    setSelectedAddField({ systemField, type: FIELD_TYPES.DEFAULT });
    toggleAddFieldModal();
  }, [JSON.stringify(selectedAddField), openAddFieldModal]);

  const handleChangeAddField = useCallback((objField) => {
    setSelectedAddField({ ...selectedAddField, ...objField });
  }, [JSON.stringify(selectedAddField)]);

  const handleSaveAddField = useCallback(() => {
    let nextSystemFields;

    if (selectedAddField.type === FIELD_TYPES.DEFAULT) {
      nextSystemFields = {
        ...systemFields,
        [selectedAddField.systemField]: {
          ...systemFields[selectedAddField.systemField],
          [selectedAddField.fieldName]: [selectedAddField.value]
        }
      };
    } else {
      nextSystemFields = {
        ...systemFields,
        [selectedAddField.systemField]: {
          ...systemFields[selectedAddField.systemField],
          [selectedAddField.fieldName]: [
            selectedAddField.customColumns
              .reduce((result, { column, value }) => ({ ...result, [column]: value }), {})
          ]
        }
      };
    }

    Request.updateSystemFields(nextSystemFields).then(data => {
      console.log('data: ', data);
      setSystemFields(nextSystemFields);
      toggleAddFieldModal();
    });
  }, [JSON.stringify({ openAddFieldModal, selectedAddField, systemFields })]);

  const handleChange = useCallback((nextValue) => {
    setSelectedField({
      ...selectedField,
      value: nextValue
    });
  }, [JSON.stringify(selectedField)]);

  const toggleAddFieldModal = useCallback(() => {
    setOpenAddFieldModal(!openAddFieldModal);
  }, [openAddFieldModal]);

  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const handleEdit = useCallback((paramsField) => {
    const { field, value, ...params } = paramsField;
    setSelectedField({ field, value });
    setSelectedParams({
      ...params,
      oldValue: value
    });

    toggleModal();
  }, [JSON.stringify({ selectedParams, systemFields, selectedField, openModal })]);

  const handleSave = useCallback(() => {
    let nextSystemFields, nextValues = [], index;

    const action = {
      [ACTIONS.Add]: () => {
        nextValues = [...selectedParams.values, selectedField.value];

      },
      [ACTIONS.AddKey]: () => {
        const path = (selectedParams.keys || []).length
          ? `${(selectedParams.keys || []).join('.')}.${selectedField.value}`
          : selectedField.value;

        const value = (selectedParams.customColumns || []).reduce((result, item) => {
          return {
            ...result,
            [item.key]: item.value
          }
        }, {});
        nextValues = setByDot(selectedParams.values, path, value);
      },
      [ACTIONS.RemoveKey]: () => {
        const path = (selectedParams.keys || []).join('.');
        deleteByDot(selectedParams.values, path);
        nextValues = selectedParams.values;
      },
      [ACTIONS.Edit]: () => {
        const values = [...selectedParams.values];
        if (!selectedParams.isValObject) {
           index = values.findIndex(value => value === selectedParams.oldValue);
          nextValues = values.filter((value, i) => i !== index);
        } else {
          const keyId = Object.keys(selectedField.value).shift();
          index = values.findIndex(value => value[keyId] === selectedParams.oldValue[keyId]);
          nextValues = values.filter((value, i ) => i !== index);
        }
        nextValues.splice(index, 0, selectedField.value);
      },
      [ACTIONS.Remove]: () => {
        const values = [...selectedParams.values];
        if (!selectedParams.isValObject) {
          index = values.findIndex(value => value === selectedParams.oldValue);
          nextValues = values.filter((value, i) => i !== index);
        } else {
          const keyId = Object.keys(selectedField.value).shift();
          index = values.findIndex(value => value[keyId] === selectedParams.oldValue[keyId]);
          nextValues = values.filter((value, i ) => i !== index);
        }

      }
    }[selectedParams.action];
    action();

    if (Array.isArray(nextValues) && nextValues.length || isObject(nextValues)) {
      nextSystemFields = {
        ...systemFields,
        [selectedParams.systemField]: {
          ...systemFields[selectedParams.systemField],
          [selectedField.field]: nextValues
        }
      };
    } else {
      nextSystemFields = { ...systemFields };
      delete nextSystemFields[selectedParams.systemField][selectedField.field];
    }

    // setSystemFields(nextSystemFields);
    // toggleModal();

    Request.updateSystemFields(nextSystemFields).then(data => {
      console.log('data: ', data);
      setSystemFields(nextSystemFields);
      toggleModal();
    });
  }, [JSON.stringify({ selectedField, selectedParams, openModal, systemFields })]);

  useEffect(() => {
    Request.getSystemFields()
      .then(sFields => {

        setSystemFields(sFields);
      });
  }, []);

  console.log('selectedAddField: ', selectedAddField);
  console.log('selectedParams: ', selectedParams);

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="justify-content-lg-start px-4 py-4">
          <div className="font-weight-bold font-size-xxl mb-1 text-center">Fields</div>
          <div className="d-flex flex-row flex-wrap d-block">
            {
              Object.keys(systemFields.Fields || {})
                .map(field => renderTableField({ systemField: 'Fields', field, values: systemFields.Fields[field], handleEdit, openColapse, setOpenColapse, classes }))
            }
            {
              systemFields.Fields &&
                <div className="position-relative px-5 py-3 mt-3">
                  <Button
                    className="p-2 text-primary" style={{ position: 'relative', top: '30%' }}
                    title='Add Field'
                    onClick={() => handleAddField('Fields')}
                  >
                    <AddTwoToneIcon style={{ width: 80, height: 80 }} />
                  </Button>
                </div>
            }
          </div>
          <div className="divider mt-3" />
        </div>
        {
          allDocTypes.map(docType => (
            <div key={docType}>
              {
                // console.log('Fields: ', Object.keys(systemFields[`${docType}Fields`] || {})) &&

                systemFields[`${docType}Fields`] &&
                  (<div className="justify-content-lg-start px-4 py-4">
                    <div className="font-weight-bold font-size-xxl mb-1 text-center">{docType} Fields</div>
                    <div className="d-flex flex-row flex-wrap">
                      {
                        Object.keys(systemFields[`${docType}Fields`])
                          .map(field => renderTableField({ systemField: `${docType}Fields`, field, values: systemFields[`${docType}Fields`][field], handleEdit, openColapse, setOpenColapse, classes }))
                      }
                      {
                        systemFields.Fields &&
                        <div className="position-relative px-5 py-3 mt-3">
                          <Button
                            className="p-2 text-primary"
                            style={{ position: 'relative', top: '30%' }}
                            title='Add Field'
                            onClick={() => handleAddField(`${docType}Fields`)}
                          >
                            <AddTwoToneIcon style={{ width: 80, height: 80 }} />
                          </Button>
                        </div>
                      }
                    </div>
                    <div className="divider mt-2" />
                  </div>)
              }
            </div>
          ))
        }
      </Card>
      <Dialog
        fullWidth
        scroll="body"
        maxWidth="sm"
        open={openModal}
        onClose={toggleModal}
        classes={{
          paper: 'modal-content border-0 bg-white rounded-lg p-3 p-xl-0'
        }}
      >
        <DialogTitle disableTypography className={DialogStyles.root}>
          <Typography id="form-dialog-title" className="text-lg-center">
            { `${selectedParams.action} ${selectedField.field}` }
          </Typography>
          <IconButton aria-label="close" className={DialogStyles.closeButton} onClick={toggleModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
            <>
              {
                ![ACTIONS.Remove, ACTIONS.RemoveKey].includes(selectedParams.action) && !selectedParams.isValObject &&
                  <TextField
                    fullWidth
                    variant="outlined"
                    className="mb-3"
                    label={selectedField.field}
                    value={selectedField.value || ''}
                    onChange={e => handleChange(e.target.value)}
                  />
              }
            </>
            <>
              {
                isObject(selectedParams.values) && selectedParams.action !== ACTIONS.RemoveKey &&
                  <>
                    <TextField
                      fullWidth
                      select
                      variant="outlined"
                      className="mb-3"
                      id="standard-multiline-flexible"
                      value={selectedParams.type || OBJECT_FIELD_TYPES.OBJECT}
                      onChange={(e) => {
                        setSelectedParams({ ...selectedParams, type: e.target.value });
                      }}
                      label="Type"
                    >
                      {
                        Object.keys(OBJECT_FIELD_TYPES).map(type =>(<MenuItem key={type} value={OBJECT_FIELD_TYPES[type]}>{OBJECT_FIELD_TYPES[type]}</MenuItem>))
                      }
                    </TextField>
                    {
                      selectedParams.type === OBJECT_FIELD_TYPES.VALUES &&
                        (selectedParams.customColumns || [{ key: '', value: '' }]).map(({ column, value }, index) => (
                          <div key={index}>
                            <TextField
                              variant="outlined"
                              className="mb-3 w-43 mr-3"
                              label="Key"
                              value={((selectedParams.customColumns || [])[index] || {}).key || ''}
                              onChange={(e) => {
                                let customColumns;
                                if (!selectedParams.customColumns) {
                                  customColumns = [{ key: e.target.value, value: '' }]
                                } else {
                                  customColumns = [...selectedParams.customColumns];
                                  customColumns[index] = { ...customColumns[index], key: e.target.value }
                                }

                                setSelectedParams({ ...selectedParams, customColumns });
                              }}
                            />
                            <TextField
                              variant="outlined"
                              className="mb-3 w-43 mr-1"
                              label="Value"
                              value={((selectedParams.customColumns || [])[index] || {}).value || ''}
                              onChange={(e) => {
                                let customColumns;
                                if (!selectedParams.customColumns) {
                                  customColumns = [{ key: '', value: e.target.value }];
                                } else {
                                  customColumns = [...selectedParams.customColumns];
                                  customColumns[index] = { ...customColumns[index], value: e.target.value };
                                }

                                setSelectedParams({ ...selectedParams, customColumns });
                              }}
                            />
                            {
                              !!index &&
                              <Button
                                className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center mt-3"
                                onClick={() => {
                                  const customColumns = selectedParams.customColumns.filter((_, i) => index !== i);
                                  setSelectedParams({ ...selectedParams, customColumns });
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={['fas', 'times']}
                                  className="font-size-sm"
                                />
                              </Button>
                            }
                          </div>
                        ))

                    }
                    {
                      selectedParams.type === OBJECT_FIELD_TYPES.VALUES &&
                        <Button
                          size="small"
                          className="btn-neutral-primary"
                          onClick={() => setSelectedParams({
                            ...selectedParams,
                            customColumns: [...(selectedParams.customColumns || [{ key: '', value: '' }]), {
                              key: '',
                              value: ''
                            }]
                          })}
                        >
                        <span className="btn-wrapper--icon">
                          <FontAwesomeIcon icon={['fas', 'plus-circle']}/>
                        </span>
                          <span className="btn-wrapper--label">Add Column</span>
                        </Button>
                    }
                  </>
              }
            </>
            <>
              {
                selectedParams.action !== ACTIONS.Remove && selectedParams.isValObject &&
                  Object.keys(selectedParams.values[0]).map(key => (
                    <TextField
                      fullWidth
                      variant="outlined"
                      className="mb-3"
                      label={key}
                      key={key}
                      value={(selectedField.value || {})[key] || ''}
                      onChange={e => handleChange({
                        ...selectedField.value,
                        [key]: e.target.value
                      })}
                    />
                  ))
              }
            </>
            <>
              {
                (selectedParams.action === ACTIONS.Remove && selectedParams.values.length > 1 || selectedParams.action === ACTIONS.RemoveKey) &&
                  <div className="m-5"><b>Are you sure you want to delete: </b> { JSON.stringify(isObject(selectedParams.values) ? selectedParams.keyName : selectedField.value) }</div>
              }
              {
                selectedParams.action === ACTIONS.Remove && selectedParams.values.length === 1 &&
                <div className="m-5"><b>Are you sure you want to delete: </b> { JSON.stringify(selectedField.value) } and <b>Field: </b> { JSON.stringify(selectedField.field) }</div>
              }
            </>
        </DialogContent>
        <DialogActions className="p-4">
          <Button
            onClick={toggleModal}
            className="btn-neutral-secondary text-danger">
            <span className="btn-wrapper--label">Cancel</span>
          </Button>
          <Button onClick={handleSave} className="btn-primary m-2">
            <span className="btn-wrapper--label">Save</span>
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        scroll="body"
        maxWidth="sm"
        open={openAddFieldModal}
        onClose={toggleAddFieldModal}
        classes={{
          paper: 'modal-content border-0 bg-white rounded-lg p-3 p-xl-0'
        }}
      >
        <DialogTitle disableTypography className={DialogStyles.root}>
          <IconButton aria-label="close" className={DialogStyles.closeButton} onClick={toggleAddFieldModal}>
            <CloseIcon />
          </IconButton>
          <Typography id="form-dialog-title" className="text-lg-center">
            Add New Field
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            select
            variant="outlined"
            className="mb-3"
            id="standard-multiline-flexible"
            value={selectedAddField.systemField || ''}
            onChange={(e) => handleChangeAddField({ systemField: e.target.value })}
            label="System Field"
          >
            <MenuItem key="Fields" value="Fields">Fields</MenuItem>
            {
              allDocTypes.map(docType =>(<MenuItem key={docType} value={`${docType}Fields`}>{`${docType}Fields`}</MenuItem>))
            }
          </TextField>
          <TextField
            fullWidth
            select
            variant="outlined"
            className="mb-3"
            id="standard-multiline-flexible"
            value={selectedAddField.type}
            onChange={(e) => handleChangeAddField({ type: e.target.value })}
            label="Type"
          >
            {
              Object.keys(FIELD_TYPES).map(type =>(<MenuItem key={type} value={FIELD_TYPES[type]}>{FIELD_TYPES[type]}</MenuItem>))
            }
          </TextField>
          {
            selectedAddField.type === FIELD_TYPES.DEFAULT &&
              <>
                <TextField
                  fullWidth
                  variant="outlined"
                  className="mb-3"
                  label="Name"
                  value={selectedAddField.fieldName || ''}
                  onChange={(e) => handleChangeAddField({ fieldName: e.target.value })}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  className="mb-3"
                  label="Value"
                  value={selectedAddField.value || ''}
                  onChange={(e) => handleChangeAddField({ value: e.target.value })}
                />
              </>
          }
          {
            selectedAddField.type === FIELD_TYPES.CUSTOM &&
              <>
                <TextField
                  fullWidth
                  variant="outlined"
                  className="mb-3"
                  label="Name"
                  value={selectedAddField.fieldName || ''}
                  onChange={(e) => handleChangeAddField({ fieldName: e.target.value })}
                />
                {
                  (selectedAddField.customColumns || [{ column: '', value: '' }]).map(({ column, value }, index) => (
                    <div key={index}>
                      <TextField
                        variant="outlined"
                        className="mb-3 w-43 mr-3"
                        label="Column Name"
                        value={((selectedAddField.customColumns || [])[index] || {}).column || ''}
                        onChange={(e) => {
                          let customColumns;
                          if (!selectedAddField.customColumns) {
                            customColumns = [{ column: e.target.value, value: '' }]
                          } else {
                            customColumns = [...selectedAddField.customColumns];
                            customColumns[index] = { ...customColumns[index], column: e.target.value }
                          }

                          handleChangeAddField({ customColumns })
                        }}
                      />
                      <TextField
                        variant="outlined"
                        className="mb-3 w-43 mr-1"
                        label="Value"
                        value={((selectedAddField.customColumns || [])[index] || {}).value || ''}
                        onChange={(e) => {
                          let customColumns;
                          if (!selectedAddField.customColumns) {
                            customColumns = [{ column: '', value: e.target.value }];
                          } else {
                            customColumns = [...selectedAddField.customColumns];
                            customColumns[index] = { ...customColumns[index], value: e.target.value };
                          }

                          handleChangeAddField({ customColumns });
                        }}
                      />
                      {
                        !!index &&
                          <Button
                            className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center mt-3"
                            onClick={() => {
                              const customColumns = selectedAddField.customColumns.filter((_, i) => index !== i);
                              handleChangeAddField({ customColumns });
                            }}
                          >
                            <FontAwesomeIcon
                              icon={['fas', 'times']}
                              className="font-size-sm"
                            />
                          </Button>
                      }
                    </div>
                  ))
                }
                <Button
                  size="small"
                  className="btn-neutral-primary"
                  onClick={() => handleChangeAddField({
                    customColumns: [...(selectedAddField.customColumns || [{ column: '', value: '' }]), { column: '', value: '' }]
                  })}
                >
                  <span className="btn-wrapper--icon">
                    <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                  </span>
                  <span className="btn-wrapper--label">Add Column</span>
                </Button>
              </>
          }
        </DialogContent>
        <DialogActions className="p-4">
          <Button
            onClick={toggleAddFieldModal}
            className="btn-neutral-secondary text-danger">
            <span className="btn-wrapper--label">Cancel</span>
          </Button>
          <Button onClick={handleSaveAddField} className="btn-primary m-2">
            <span className="btn-wrapper--label">Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
