import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Card,
  MenuItem,
  Button,
  TextField,
  Grid
} from '@material-ui/core';

import { DISPLAY_CONTROLLERS, FIELD_TITLES, IDOC_TYPE, GDOC_TYPE, TITLES } from '../../../constants';
import { useHideBorderStyles } from './../../../assets/styles';
import { setPageTitle } from '../../../reducers/ThemeOptions';
import { Request } from './../../../services';
import {
  findItem,
  prepareTree,
  getChildTypes,
  calculateGridData,
  composeGridValues,
  getTotalByGridValues
} from '../../../utils/common';

import { RenderField } from './../../controls';
import { WrapperSimple } from '../../layout-components';

const FIELD_TYPES = Object.keys(FIELD_TITLES);
const DISPLAY_CONTROLLERS_VALUES = Object.values(DISPLAY_CONTROLLERS);
const allowDocTypes = [GDOC_TYPE, IDOC_TYPE];

const DocCreate = (props) => {
  const history = useHistory();
  const { setPageTitle, match: { params: { parentDocId, docType } = {} } = {} } = props;

  const [parentDoc, setParentDoc] = useState();
  const [doc, setDoc] = useState({});
  const [selectedFieldItems, setSelectedFieldItems] = useState({});
  const [selectedDocType, setSelectedDocType] = useState('');
  const [childTypes, setChildTypes] = useState([]);
  const [docsConfig, setDocsConfig] = useState();
  const [docs, setDocs] = useState();
  const DivStyles = useHideBorderStyles();

  const isIdeaLibrary = !parentDocId && !docType;

  useEffect(() => {
    const { TimeFields } = selectedFieldItems || {};
    const docConfig = docsConfig?.[selectedDocType];
    const data = calculateGridData({ docType: selectedDocType, defaultValues: {}, TimeFields, docConfig });

    if (!data) return;

    setSelectedFieldItems({
      ...selectedFieldItems,
      [FIELD_TITLES.TimeFields]: {
        ...selectedFieldItems[FIELD_TITLES.TimeFields],
        [`${selectedDocType}GridValues`]: data.gridValues,
        [`${selectedDocType}Total`]: data.total,
        ...(
          !selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${selectedDocType}StartDate`]
            ? { [`${selectedDocType}StartDate`]: new Date() }
            : {}
        )
      }
    });
  }, [
    selectedDocType,
    selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${selectedDocType}PerPeriod`],
    selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${selectedDocType}PeriodType`],
    selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${selectedDocType}NumberOfYears`],
    selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${selectedDocType}StartDate`]
  ]);

  const handleGridChange = useCallback((year, index, val) => {
    if (!selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${selectedDocType}GridValues`]) return;

    const nextDocGridValues = composeGridValues({
      gridValues: selectedFieldItems[FIELD_TITLES.TimeFields][`${selectedDocType}GridValues`],
      year,
      index,
      val
    });

    setSelectedFieldItems({
      ...selectedFieldItems,
      [FIELD_TITLES.TimeFields]: {
        ...selectedFieldItems[FIELD_TITLES.TimeFields],
        [`${selectedDocType}GridValues`]: nextDocGridValues,
        [`${selectedDocType}Total`]: getTotalByGridValues(nextDocGridValues)
      }
    });
  }, [selectedFieldItems?.[FIELD_TITLES.TimeFields]?.[`${selectedDocType}GridValues`]]);

  const setDefaultFieldsData = useCallback(() => {
    if (!selectedDocType || !docsConfig) return;

    const nextDoc = FIELD_TYPES.reduce((result, fieldType) => ({
      ...result,
      [fieldType]: (docsConfig[selectedDocType][fieldType] || [])
        .filter(objField => !!objField.DisplayController && DISPLAY_CONTROLLERS_VALUES.includes(objField.DisplayController))
        .map(objField => {
          const { Label, ToolTip, DisplayPosition, DisplayController, FieldName, Items = [], ...props } = objField;

          return {
            label: Label,
            toolTip: ToolTip,
            displayPosition: DisplayPosition,
            displayController: DisplayController,
            field: FieldName,
            source: Items || [],
            value: FieldName === 'iDocGate'
              ? Items.length ? String(Items[0].Value) : ''
              : doc[FieldName],
            ...props
          }
        })
    }), { type: selectedDocType, clientName: (docsConfig[selectedDocType] || {}).DocumentType });

    setDoc(nextDoc);

    const defaultFieldsData =
      Object.keys(FIELD_TITLES).reduce((defaultFieldsType, fieldType) => {
        const defaultFields = (nextDoc[fieldType] || [])
          .reduce((defaultField, objField) => ({ ...defaultField, [objField.field]: objField.value }), {})

        return { ...defaultFieldsType, [fieldType]: defaultFields }
      }, {});

    setSelectedFieldItems(defaultFieldsData)
  }, [JSON.stringify({ selectedDocType, docsConfig })]);

  const handleFieldsSave =
    useCallback(() => {
      const nextFieldsTypes = Object.keys(selectedFieldItems).reduce((result, fieldsType) => {
        const modifyItems = selectedFieldItems[fieldsType];

        return {
          ...result,
          [fieldsType]: [
            ...doc[fieldsType].map(
              (fieldObj) => modifyItems[fieldObj.field] === undefined
                ? fieldObj
                : { ...fieldObj, value: modifyItems[fieldObj.field] }
            )
          ]
        }
      }, {});
      const nextDoc = { ...doc, ...nextFieldsTypes };
      setDoc(nextDoc);
      console.log('nextDoc: ', nextDoc)
      const dbDoc = Object.keys(FIELD_TITLES).reduce((result, fieldsType) => ({
        ...result,
        ...nextDoc[fieldsType].reduce(((fieldResult, { field, value }) =>
          field ? ({ ...fieldResult, [field]: value }) : fieldResult), {})
      }), {});

      const getParentRelation = () =>
        parentDoc.type === IDOC_TYPE && nextDoc.type === IDOC_TYPE
          ? { [`${parentDoc.type}DependencyId`]: parentDoc.nodeId }
          : { [`${parentDoc.type}Id`]: parentDoc.nodeId }
        ;
      const nextDocDb = parentDoc ? { ...dbDoc, ...getParentRelation() } : dbDoc;

      console.log('docsConfig[nextDoc.type].TargetCollection: ', docsConfig[nextDoc.type].TargetCollection)
      console.log('dbDoc: ', nextDocDb)

      Request.createDoc(docsConfig[nextDoc.type].TargetCollection, nextDocDb).then(data => {
        console.log('created data: ', data);
        const Id = data[`${nextDoc.type}Id`] || data['Id']

        history.push(`/docs/${nextDoc.type}/${Id}`)
      })

    }, [JSON.stringify({ selectedFieldItems, doc })]);

  useEffect(() => {
    setPageTitle(TITLES.CREATEDOCUMENT);

    Promise.all([
      Request.getDocsConfig(),
      !isIdeaLibrary ? Request.getDocs() : Promise.resolve()
    ])
      .then(([docsConfig, docs]) => {
        setDocsConfig(docsConfig);
        setDocs(docs);
      });
  }, []);

  useEffect(() => {
    if (!docsConfig) return;

    let doc;
    if (parentDocId && !parentDoc && docs) {
      const tree = prepareTree({ docsConfig, docs });
      doc = findItem(tree, parentDocId);
      setParentDoc(doc);
      setDefaultFieldsData(doc);
    }

    if (docType && docType !== 'child-doc') {
      setSelectedDocType(docType);
      setDefaultFieldsData();
    } else if (docType && docType === 'child-doc') {
      if (doc) setChildTypes(getChildTypes(doc.type));
      if (!selectedDocType) setSelectedDocType(childTypes[0]);
      setDefaultFieldsData();
    } else if (isIdeaLibrary && selectedDocType) {
      setDefaultFieldsData();
    }

  }, [selectedDocType, childTypes, docsConfig, docs]);

  return (
    <>
      <WrapperSimple>
        <Card className="card-box mb-spacing-6-x2">
          <div className="card-footer py-3 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div className="card-header--actions">
                <Button size="small" className="btn-neutral-primary">
                  <span
                    className="btn-wrapper--label"
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                  >
                    Back
                  </span>
                </Button>
              </div>
            </div>
          </div>
          <div className="card-footer py-3 d-flex justify-content-between">
            {
              docsConfig && parentDoc && (
                <div className="card-header-alt px-4 pt-4 pb-2 w-100">
                  <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                    Parent Document: {parentDoc.clientName}
                  </h6>
                </div>
              )
            }
            {
              docsConfig && docType !== 'child-doc' && selectedDocType && (
                <div className="card-header-alt px-4 pt-4 pb-2 w-100">
                  <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                    Document Type: {(docsConfig[selectedDocType] || {}).DocumentType}
                  </h6>
                </div>
              )
            }
            {
              docsConfig && docType === 'child-doc' && selectedDocType && (
                <div className="card-header-alt px-4 pt-4 pb-2 w-100">
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    className="mb-3"
                    id="standard-multiline-flexible"
                    key="doc-type"
                    value={selectedDocType}
                    onChange={e => { setSelectedDocType(e.target.value) }}
                    label='Document Type'
                  >
                    {
                      childTypes.map((type) => (<MenuItem key={type} value={type}>{type}</MenuItem>))
                    }
                  </TextField>
                </div>
              )
            }
            {
              docsConfig && !docType && (
                <div className="card-header-alt px-4 pt-4 pb-2 w-100">
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    className="mb-3"
                    id="standard-multiline-flexible"
                    key="doc-type"
                    value={selectedDocType}
                    onChange={e => { setSelectedDocType(e.target.value) }}
                    label='Document Type'
                  >
                    {
                      allowDocTypes.map((type) => (<MenuItem key={type} value={type}>{type}</MenuItem>))
                    }
                  </TextField>
                </div>
              )
            }
          </div>
          <div className="table-responsive-md">
            <div className="d-flex flex-wrap">
              <Grid container spacing={0}>
                <Grid item sm={12} className="pt-3">
                  {
                    FIELD_TYPES.map(fieldsType => (
                      fieldsType !== FIELD_TITLES.Aggregations && !!((doc || {})[fieldsType] || []).length &&
                      <div key={fieldsType} className="w-100 p-1">
                        <div
                          variant="outlined"
                          className={`card card-box d-block text-left p-5 ${DivStyles.root}`}
                        >
                          {
                            ((doc || {})[fieldsType] || []).length
                              ? (
                                <>
                                  <div className="font-weight-bold font-size-md text-lg-center w-100">
                                    {FIELD_TITLES[fieldsType]}
                                  </div>

                                  <div>
                                    {
                                      doc[fieldsType].map(item =>
                                        <RenderField
                                          {
                                            ...{
                                              item,
                                              fieldsType,
                                              selectedFieldItems,
                                              setSelectedFieldItems,
                                              handleGridChange,
                                              docType: selectedDocType
                                            }
                                          }
                                        />
                                      )
                                    }
                                  </div>
                                </>
                              )
                              : (
                                <div className="font-weight-bold font-size-md text-lg-center">
                                  {FIELD_TITLES[fieldsType]}
                                </div>
                              )
                          }
                        </div>
                      </div>
                    ))
                  }
                  <div className="p-4 text-right">
                    <Button
                      onClick={() => setDefaultFieldsData()}
                      className="btn-neutral-secondary text-danger">
                      <span className="btn-wrapper--label">Cancel</span>
                    </Button>
                    <Button onClick={() => handleFieldsSave()} className="btn-primary m-2">
                      <span className="btn-wrapper--label">Save</span>
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>

        </Card>
      </WrapperSimple>
    </>
  );
};

const mapStateToProps = (state) => ({
  pageTitle: state.ThemeOptions.pageTitle
});

const mapDispatchToProps = (dispatch) => ({
  setPageTitle: (pageTitle) => dispatch(setPageTitle(pageTitle))
});

export default connect(mapStateToProps, mapDispatchToProps)(DocCreate);
