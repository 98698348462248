import axios from 'axios';
import {
  PATHFINDER_CLIENT_DOMAIN_PROD,
  PATHFINDER_API_DOMAIN_PROD,
  PATHFINDER_API_DOMAIN_RC
} from './../constants';
import AuthService, { getToken } from './../services/Auth';

const { PATHFINDER_API_DOMAIN = PATHFINDER_API_DOMAIN_RC } = [
  {
    condition: window.location.href.includes(PATHFINDER_CLIENT_DOMAIN_PROD),
    PATHFINDER_API_DOMAIN: PATHFINDER_API_DOMAIN_PROD
  }
].find(({ condition }) => condition) || {};

let instance = {};
export const getAxios = () => {
  const token = getToken();
  if (instance[token]) return instance[token];

  instance[token] = axios.create({ baseURL: PATHFINDER_API_DOMAIN });
  instance[token].interceptors.request.use(
    config => {
      if (token) config.headers['Authorization'] = `Bearer ${token}`;

      return config;
    }
  );
  instance[token].interceptors.response.use(
    response => response.data,
    error => {
      if (error.response && ([401, 403].includes(error.response.status))) {
        AuthService.logout();
      }

      return Promise.reject(error);
    }
  );

  return instance[token];
};
