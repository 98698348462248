import { fade, makeStyles } from '@material-ui/core/styles';

const useTreeItemStyles = makeStyles((theme) => {
  return ({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: 'rgba(65, 145, 255, 0.05)'
      },
      // '&:focus > $content, &$selected > $content': {
      //   backgroundColor: `var(--tree-view-bg-color, #e8e8e8)`,
      //   color: 'var(--tree-view-color)',
      // },
      // '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      //   backgroundColor: 'transparent',
      // },
    },
    content: {
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      // '$expanded > &': {
      //   fontWeight: theme.typography.fontWeightRegular,
      // },
    },
    group: {
      // marginLeft: 20,
      // '& $content': {
      //   paddingLeft: theme.spacing(2),
      // },
      marginLeft: '7px !important',
      paddingLeft: '18px !important',
      borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
      '&:hover > $actionBtn': {
        opacity: 1
      },
      '& .MuiTextField-root': {
        marginRight: theme.spacing(1),
        width: '25ch',
      }
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    clientName: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
    actionBtn: {
      opacity: '0',
      backgroundColor: '#ffffff',
      padding: '4px',
      position: 'relative',
      margin: '0 2px',
      zIndex: 100
    }
  });
});

export {
  useTreeItemStyles
}
