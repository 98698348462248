import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  InputAdornment,
  Card,
  Button,
  TextField,
  Link,
  Dialog
} from '@material-ui/core';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

import { ACTIONS, DOC_TYPES, FIELD_TITLES, DISPLAY_CONTROLLERS, CONTROL_DOC_CDOC } from './../../../constants';
import { setPageTitle } from './../../../reducers/ThemeOptions';
import { Request } from './../../../services';
import { calculateGridData, composeGridValues, getTotalByGridValues } from '../../../utils/common';
import { getFirstDayOfMonth } from './../../../utils/date';
import { RenderField } from './../../controls';

const DISPLAY_CONTROLLERS_VALUES = Object.values(DISPLAY_CONTROLLERS);
const FIELD_TYPES = Object.keys(FIELD_TITLES);

const mapGridSchema = {
  cDoc: {
    Resources: {
      render: ({ doc, docType }) => {
        const fieldId = `${docType}Id`;

        return (
          <td key="resources" className="text-center">
            <Link href={`/control-docs/cDoc/${doc[fieldId]}`} variant="body2">
              See resources
            </Link>
          </td>
        )
      }
    }
  }
};

export const ControlDocsModify = (props) => {
  const history = useHistory();
  const { setPageTitle, match: { params: { docType, docId } = {} } = {} } = props;
  const isCreate = docId === ACTIONS.Add;
  const [docsConfig, setDocsConfig] = useState({});
  const [defaultDoc, setDefaultDoc] = useState([]);
  const [doc, setDoc] = useState({});
  const [removeResource, setRemoveResource] = useState();
  const [schema, setSchema] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [resources, setResources] = useState([]);

  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const handleChange = useCallback((field, e) => {
    if (field.DisplayController === DISPLAY_CONTROLLERS.RecordSet) {
      const resourceDocType = field?.SourceAtributes?.IdFieldName?.replace('Id', '');
      const { FieldName } = docsConfig.Fields.find(({ DisplayController }) => DisplayController === DISPLAY_CONTROLLERS.AutoCalc)

      const selectedResources = (resources[field.FieldName] || [])
        .filter((obj) => e.target.value.includes(obj[`${resourceDocType}Id`]))
      const sum = selectedResources
        .reduce((a, { [`${resourceDocType}Total`]: b = 0 }) => (Number(a) + Number(b)), 0)
      ;

      return setDoc({
        ...doc,
        [FieldName]: sum,
        [field.FieldName]: e.target.value
      });
    }

    return setDoc({
      ...doc,
      [field.FieldName]: e instanceof Date ? getFirstDayOfMonth(e) : e.target.value
    });
  }, [doc, docsConfig, resources]);

  const handleCancel = useCallback(() => {
    setDoc(defaultDoc);
  }, []);

  const handleSave = useCallback(() => {
    if (isCreate) {
      console.log('save doc: ', doc)

      return Request.createControlDoc(docType, doc).then((data) => {
        history.push(`/control-docs/cDoc/${data[`${docType}Id`]}`)
      });
    }
    const { _id, [`${docType}Id`]: id, ...updateData } = doc;

    return Request.updateControlDoc(docType, id, updateData);
  }, [doc]);

  useEffect(() => {
    setPageTitle(docId === ACTIONS.Add ? 'Create document' : 'Edit document');
    Promise.all([
      Request.getDocsConfig(DOC_TYPES.ControlDocs),
      isCreate ? Promise.resolve() : Request.getControlDoc(docType, docId),
    ])
      .then(([docsConfig, inputDoc = {}]) => {
        (docsConfig[docType].Fields || [])
          .filter(({ DisplayController }) => DisplayController === DISPLAY_CONTROLLERS.RecordSet)
          .map(
            field =>
              Request.getDocs({ Collection: field.SourceAtributes.Collection, [`${CONTROL_DOC_CDOC}Id`]: docId })
                .then(data => {
                  setResources({
                    ...resources,
                    [field.FieldName]: data
                  });

                  const nextSchema = field.SourceAtributes.Fields.map(key => {
                    return ({
                      label: key,
                      key,
                      ...((mapGridSchema[docType] || {})[key] || {})
                    })
                  });
                  setSchema({
                    ...schema,
                    [field.FieldName]: [
                      ...nextSchema
                    ]
                  });
                })
          );

        const nextDoc = {
          ...inputDoc,
          Resources: (inputDoc.Resources || []).map(({ rDocId: id }) => id)
        };
        setResources((inputDoc.Resources || []));

        setDocsConfig(docsConfig[docType]);
        setDefaultDoc(nextDoc);
        setDoc(nextDoc);
      });
  }, []);

  useEffect(() => {
    const data = calculateGridData({ docType, defaultValues: defaultDoc, TimeFields: doc, docConfig: docsConfig });

    if (!data) return;

    setDoc({
      ...doc,
      [`${docType}GridValues`]: data.gridValues,
      [`${docType}Total`]: data.total,
      ...(
        !doc[`${docType}StartDate`]
          ? { [`${docType}StartDate`]: new Date() }
          : {}
      )
    });
  }, [
    docType,
    doc[`${docType}PerPeriod`],
    doc[`${docType}PeriodType`],
    doc[`${docType}NumberOfYears`],
    doc[`${docType}StartDate`],
  ]);

  const handleGridChange = useCallback((year, index, val) => {
    if (!doc[`${docType}GridValues`]) return;

    const nextDocGridValues = composeGridValues({
      gridValues: doc[`${docType}GridValues`],
      year,
      index,
      val
    });

    setDoc({
      ...doc,
      [`${docType}GridValues`]: nextDocGridValues,
      [`${docType}Total`]: getTotalByGridValues(nextDocGridValues)
    });
  }, [doc[`${docType}GridValues`]]);

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header-alt px-4 pt-4 pb-2 w-100">
          <h6 className="font-weight-bold font-size-lg mb-1 text-black">
            Document Type: {docType}
          </h6>
        </div>
        <div className="px-4 py-3">
          {
            FIELD_TYPES.map(fieldsType => (
              docsConfig[fieldsType] &&
                <div key={fieldsType} className="mb-3">
                  {
                    (docsConfig[fieldsType] || [])
                      .filter(({ DisplayController }) => DISPLAY_CONTROLLERS_VALUES.includes(DisplayController))
                      .map(field => {
                        const resourceDocType = field?.SourceAtributes?.IdFieldName?.replace('Id', '');

                        return (
                          <React.Fragment key={field.FieldName}>
                            <RenderField
                              {
                                ...{
                                  item: {
                                    label: field.Label,
                                    field: field.FieldName,
                                    displayController: field.DisplayController,
                                    source: field.Items || [],
                                    resources: doc[field.FieldName] || [],
                                    allResources: resourceDocType ? (resources[field.FieldName] || []).map((obl) => ({ id: obl[`${resourceDocType}Id`], label: obl[`${resourceDocType}Name`] }) ) : []
                                  },
                                  selectedFieldItems: doc,
                                  userHandleChange: (e) => handleChange(field, e),
                                  docType,
                                  handleGridChange
                                }
                              }
                            />
                          </React.Fragment>
                        )
                      })
                  }
                </div>
            ))
          }
          <div className="p-4 text-right">
            <Button
              onClick={handleCancel}
              className="btn-neutral-secondary text-danger">
              <span className="btn-wrapper--label">Cancel</span>
            </Button>
            <Button onClick={handleSave} className="btn-primary m-2">
              <span className="btn-wrapper--label">Save</span>
            </Button>
          </div>
        </div>

        {
          (docsConfig.Fields || [])
            .filter(({ DisplayController }) => DisplayController === DISPLAY_CONTROLLERS.RecordSet)
            .map((field) =>
              <React.Fragment key={field.FieldName}>
                <div className="divider" />
                <div className="card-header-alt px-4 pt-4 pb-2 w-100">
                  <h6 className="font-weight-bold font-size-lg mb-1 text-black">
                    { field.FieldName }
                  </h6>
                </div>
                <div className="d-flex justify-content-between px-4 py-3">
                  <div className="search-wrapper">
                    <TextField
                      variant="outlined"
                      size="small"
                      id="input-search"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchTwoToneIcon />
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>
                </div>
                <div className="divider" />
                <div className="table-responsive-md">
                  <Table className="table table-hover text-nowrap mb-0">
                    <thead>
                    <tr>
                      { (schema[field.FieldName] || []).map(({ label }, index) => (<th key={`${index}-th`} className="bg-white text-center">{label}</th>)) }
                    </tr>
                    </thead>
                    <tbody>
                    {
                      (resources[field.FieldName] || [])
                        .filter(obj => (doc.Resources || []).includes(obj[field.SourceAtributes.IdFieldName]))
                        .map((doc, index) => (
                          <tr key={index}>
                            {
                              (schema[field.FieldName] || []).map(({ key, render }, subIndex) => {
                                return render
                                  ? render({ doc, index, docType, setRemoveResource, toggleModal, history })
                                  : (<td key={`${index+1}-${subIndex}-td`} className="text-center">{doc[key]}</td>)
                                }
                              )
                            }
                          </tr>
                        ))
                    }
                    </tbody>
                  </Table>
                </div>
                <Dialog open={openModal} onClose={toggleModal} classes={{ paper: 'shadow-lg rounded' }}>
                  <div className="text-center p-5">
                    <div className="avatar-icon-wrapper rounded-circle m-0">
                      <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
                        <FontAwesomeIcon icon={['fas', 'times']} className="d-flex align-self-center display-3"/>
                      </div>
                    </div>
                    <h4 className="font-weight-bold mt-4">Are you sure you want to delete this document?</h4>
                    <p className="mb-0 font-size-lg text-muted">You cannot undo this operation.</p>
                    <div className="pt-4">
                      <Button onClick={toggleModal} className="btn-neutral-secondary btn-pill mx-1">
                        <span className="btn-wrapper--label"> Cancel </span>
                      </Button>
                      <Button
                        onClick={() => {
                          setRemoveResource(undefined)
                          const nextResource = (doc.Resources || [])
                            .filter(({ Id }) => Id !== removeResource[`Id`]);
                          const nextData = {
                            ...doc,
                            Resources: nextResource
                          };
                          Request.updateControlDoc(docType, removeResource[`Id`], nextData).then(data => {
                            toggleModal();
                          });
                        }}
                        className="btn-danger mx-1"
                      >
                        <span className="btn-wrapper--label"> Delete </span>
                      </Button>
                    </div>
                  </div>
                </Dialog>
              </React.Fragment>
            )
        }
      </Card>
    </>
  );
}

const mapStateToProps = (state) => ({
  pageTitle: state.ThemeOptions.pageTitle
});

const mapDispatchToProps = (dispatch) => ({
  setPageTitle: (pageTitle) => dispatch(setPageTitle(pageTitle))
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlDocsModify);
