import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  InputLabel,
  InputAdornment,
  Card,
  MenuItem,
  Button,
  Tooltip,
  TextField,
  FormControl,
  Select,
  Link, Dialog
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import { Request } from '../../../services';
import { ACTIONS } from '../../../constants'

const mapGridSchema = {
  cDoc: {
    Resources: {
      render: ({ doc, docType }) => {
        const fieldId = `${docType}Id`;

        return (
          <td key="resources" className="text-center">
            <Link href={`/control-docs/cDoc/${doc[fieldId]}`} variant="body2">
              See resources
            </Link>
          </td>
        )
      }
    }
  }
}

const dataGridSchema = [
  {
    label: 'Actions',
    key: '',
    render: ({ doc, docType, setRemoveDoc, toggleModal, history }) => (
      <td key="actions" className="text-center">
        <Button
          className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/control-docs/cDoc/${doc[`${docType}Id`]}`)
          }}
        >
          <FontAwesomeIcon
            icon={['far', 'edit']}
            className="font-size-sm"
          />
        </Button>
        <Button
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          onClick={(e) => {
            e.preventDefault();
            setRemoveDoc(doc);
            toggleModal();
          }}
        >
          <FontAwesomeIcon
            icon={['fas', 'times']}
            className="font-size-sm"
          />
        </Button>
      </td>
    )
  }
];

export default (props) => {
  const history = useHistory();
  const { match: { params: { docType } = {} } = {} } = props;
  const [docsConfig, setDocsConfig] = useState({});
  const [docs, setDocs] = useState([]);
  const [removeDoc, setRemoveDoc] = useState();
  const [schema, setSchema] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  useEffect(() => {
    Promise.all([Request.getControlDocs()])
      .then(([data = {}]) => {
        setDocsConfig(docsConfig);

        setDocs(data[docType]);
        const doc = [...(data[docType] || [])].shift();

        if (!doc) return;

        const nextSchema = Object.keys(doc)
          .filter(key => (
            !key.includes('_id') &&
            !key.includes('GridValues') &&
            !key.includes('Resources') &&
            !key.includes('Id') &&
            !key.includes('Grid') &&
            !key.includes('Date')
          ))
          .map(key => {
            return ({
              label: key,
              key,
              ...((mapGridSchema[docType] || {})[key] || {})
            })
        });
        setSchema([
          ...nextSchema,
          ...dataGridSchema
        ]);
      });
  }, []);

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
          <div className="d-flex justify-content-between px-4 py-3">
            <div className="search-wrapper">
              <TextField
                variant="outlined"
                size="small"
                id="input-search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
              />
            </div>

          <div className="card-header--actions">
            <Button
              size="small"
              className="btn-neutral-primary"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/control-docs/cDoc/${ACTIONS.Add}`)
              }}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'plus-circle']} />
              </span>
              <span className="btn-wrapper--label">Add {docType}</span>
            </Button>
          </div>
        </div>
        <div className="divider" />
        <div className="table-responsive-md">
          <Table className="table table-hover text-nowrap mb-0">
            <thead>
            <tr>
              { schema.map(({ label }, index) => (<th key={`${index}-th`} className="bg-white text-center">{label}</th>)) }
            </tr>
            </thead>
            <tbody>
            {
              docs.map((doc, index) => (
                <tr key={index}>
                  {
                    schema.map(({ key, render }, subIndex) => {
                      return render
                        ? render({ doc, index, docType, setRemoveDoc, toggleModal, history })
                        : (<td key={`${index+1}-${subIndex}-td`} className="text-center">{doc[key]}</td>)
                      }
                    )
                  }
                </tr>
              ))
            }
            </tbody>
          </Table>
        </div>
      </Card>
      <Dialog open={openModal} onClose={toggleModal} classes={{ paper: 'shadow-lg rounded' }}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon icon={['fas', 'times']} className="d-flex align-self-center display-3"/>
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">Are you sure you want to delete this document?</h4>
          <p className="mb-0 font-size-lg text-muted">You cannot undo this operation.</p>
          <div className="pt-4">
            <Button onClick={toggleModal} className="btn-neutral-secondary btn-pill mx-1">
              <span className="btn-wrapper--label"> Cancel </span>
            </Button>
            <Button
              onClick={() => {
                Request.removeControlDoc(docType, removeDoc[`${docType}Id`]).then(data => {
                  console.log('data: ', data);
                  setRemoveDoc(undefined)
                  toggleModal();
                  setDocs(docs.filter(({ [[`${docType}Id`]]: filterDocId }) => filterDocId !== removeDoc[`${docType}Id`]))
                })
              }}
              className="btn-danger mx-1"
            >
              <span className="btn-wrapper--label"> Delete </span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}
