import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Container,
  Card,
  Button,
  List,
  ListItem,
  TextField
} from '@material-ui/core';

import hero3 from '../../../assets/images/hero-bg/hero-5.jpg';
import { AuthService, Request } from '../../../services';
import { Redirect } from 'react-router';
import Alert from '@material-ui/lab/Alert/Alert';

export default function LivePreviewExample() {
  const [redirect, setRedirect] = useState('');
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState([]);
  const isAuthenticated = AuthService.isAuthenticated();

  const handleSelectChange = useCallback((type) => ({
    [type]: (value) => {
      setFields({ ...fields, [type]: value.target.value });
    }
  }[type]),[JSON.stringify(fields)]);

  const handleSubmit = useCallback(() => {
    setErrors([]);

    Request.signup(fields)
      .then((user) => {
        console.log(user)
      })
      .catch((error) => {
        setErrors(error.errors || []);
      });
  }, [JSON.stringify(fields)]);

  useEffect(() => {
    if (isAuthenticated) setRedirect('/');
  }, [isAuthenticated]);

  if (redirect) return (<Redirect to={redirect}/>);

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="hero-wrapper w-100 bg-composed-wrapper bg-light-pure min-vh-100">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div
              className="bg-composed-wrapper--image opacity-6"
              style={{ backgroundImage: 'url(' + hero3 + ')' }}
            />
            <div className="bg-composed-wrapper--bg bg-second opacity-7" />
            <div className="bg-composed-wrapper--bg bg-premium-dark opacity-5" />
            <div className="bg-composed-wrapper--content p-3 p-md-5">
              <Container>
                <Card className="rounded-sm modal-content p-3 bg-white-10">
                  <Card className="rounded-sm shadow-none font-size-sm p-3 p-sm-0">
                    <Grid container spacing={0}>
                      <Grid
                        item
                        lg={12}
                        className="d-flex align-items-center justify-content-center flex-column">
                        <div className="divider-v divider-v-lg d-none d-lg-block" />
                        <div className="text-center mt-4">
                          <h1 className="font-size-xxl mb-1 font-weight-bold">
                            Create account
                          </h1>
                          <p className="mb-0 text-black-50">
                            Start benefiting from our tools right away!
                          </p>
                        </div>
                        <div className="px-5 py-4">
                          <div className="mb-3">
                            <label className="font-weight-bold mb-2">
                              Email address
                            </label>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Enter your email address"
                              type="email"
                              onChange={handleSelectChange('email')}
                              value={fields.email || ''}
                            />
                          </div>
                          <div className="mb-3">
                            <div className="d-flex justify-content-between">
                              <label className="font-weight-bold mb-2">
                                Password
                              </label>
                            </div>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              placeholder="Enter your password"
                              type="password"
                              onChange={handleSelectChange('password')}
                              value={fields.password || ''}
                            />
                          </div>
                          <Grid container spacing={6}>
                            <Grid item md={6}>
                              <div>
                                <label className="font-weight-bold mb-2">
                                  First name
                                </label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="Enter your first name"
                                  onChange={handleSelectChange('firstName')}
                                  value={fields.firstName || ''}
                                />
                              </div>
                            </Grid>
                            <Grid item md={6}>
                              <div>
                                <label className="font-weight-bold mb-2">
                                  Last name
                                </label>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  fullWidth
                                  placeholder="Enter your last name"
                                  onChange={handleSelectChange('lastName')}
                                  value={fields.lastName || ''}
                                />
                              </div>
                            </Grid>
                          </Grid>
                          {
                            errors.map((error, index) => (
                              <Alert
                                key={index}
                                className="mb-4"
                                severity="error">
                                {error}
                              </Alert>
                            ))
                          }
                          <div className="my-4">
                            By clicking the <strong>Create account</strong>{' '}
                            button below you agree to our terms of service and
                            privacy statement.
                          </div>
                          <div className="text-center mb-4">
                            <Button
                              className="btn-primary text-uppercase font-weight-bold font-size-sm my-3"
                              onClick={handleSubmit}
                            >
                              Create account
                            </Button>
                          </div>
                          <div className="text-center text-black-50 mt-3">
                            Already have an account? &nbsp;
                            <a
                              className="text-first"
                              href="/login"
                            >
                              Login
                            </a>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </Card>
              </Container>
            </div>
          </div>
          <div className="hero-footer w-100 pb-4">
            <Container>
              <div className="py-3 d-block d-lg-flex font-size-xs justify-content-between">
                <div className="text-center d-block mb-3 mb-md-0 text-white">
                  Copyright &copy; 2021 - Foresidegroup.com
                </div>
                <List
                  component="div"
                  className="nav-transparent text-nowrap d-flex justify-content-center">
                  <ListItem
                    button
                    className="text-white-50"
                    href="#/"
                    onClick={(e) => e.preventDefault()}>
                    Privacy Policy
                  </ListItem>
                  <ListItem
                    button
                    className="text-white-50"
                    href="#/"
                    onClick={(e) => e.preventDefault()}>
                    Terms of Service
                  </ListItem>
                </List>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
