import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthService } from '../../../services';

const ProtectedRoute = (props) => {
  return (
    AuthService.isAuthenticated()
      ? (<Route {...props} />)
      : (<Redirect to={{ pathname: '/login', state: { from: props.location } }}/>)
  );
};

export default ProtectedRoute;
