import  { login, recover, signup, getProfile, getUsers } from './users';
import  { getMenus, getSystemFields, updateSystemFields } from './settings';
import  {
  getDocsConfig,
  getConfiguration,
  createClientConfiguration,
  updateClientConfiguration,
  getDocs,
  createDoc,
  updateDoc,
  removeDoc,
  getControlDocs,
  updateControlDoc,
  removeControlDoc,
  getControlDoc,
  createControlDoc
} from './docs';

export default {
  login,
  recover,
  signup,
  getProfile,
  getUsers,
  getMenus,
  getDocsConfig,
  getConfiguration,
  createClientConfiguration,
  updateClientConfiguration,
  getDocs,
  createDoc,
  updateDoc,
  removeDoc,
  getSystemFields,
  updateSystemFields,
  getControlDocs,
  updateControlDoc,
  removeControlDoc,
  getControlDoc,
  createControlDoc
}
