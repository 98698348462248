export const PATHFINDER_CLIENT_DOMAIN_PROD = 'https://console.mmpathfinder.com/';

export const PATHFINDER_API_DOMAIN_PROD = 'https://web-api.mmpathfinder.com/';
export const PATHFINDER_API_DOMAIN_RC = 'https://web-api.mmpathfinder.net/';

export const MENU_BLOCKS = {
  leftSideBar: 'leftSideBar',
  topMenu: 'topMenu'
};

export const MENU_ITEM_TYPES = {
  link: 'link',
  subMenu: 'subMenu'
};

export const FIELD_TITLES = {
  Fields: 'Fields',
  StandardDocFields: 'StandardDoc Fields',
  SystemClientFields: 'SystemClient Fields',
  DocClientFields: 'DocClient Fields',
  RichTextFields: 'RichText Fields',
  ClientTags: 'ClientTags',
  TimeFields: 'TimeFields',
  Aggregations: 'Aggregations',
  Calculations: 'Calculations'
};

export const CONFIG_TYPES = {
  xDocs: 'xDocs',
  ControlDocs: 'ControlDocs'
};

export const CONFIG_FIELDS = {
  FieldName: 'FieldName',
  Label: 'Label',
  DisplayController: 'DisplayController',
  DisplayPosition: 'DisplayPosition',
}

export const GDOC_TYPE = 'gDoc';
export const BDOC_TYPE = 'bDoc';
export const SDOC_TYPE = 'sDoc';
export const IDOC_TYPE = 'iDoc';
export const RDOC_TYPE = 'rDoc';
export const MDOC_TYPE = 'mDoc';

export const CONTROL_DOC_CDOC = 'cDoc';

export const DOC_LEVELS = [
  [GDOC_TYPE],
  ['bDoc', SDOC_TYPE],
  [IDOC_TYPE],
  ['inDoc','outDoc', 'pDoc', 'prDoc', 'qDoc'],
  ['rDoc', MDOC_TYPE, 'kDoc', 'tDoc', 'qsDoc']
];

export const DOC_HIERARCHY = {
  [GDOC_TYPE]: ['bDoc', SDOC_TYPE],
  [SDOC_TYPE]: [IDOC_TYPE],
  [IDOC_TYPE]: ['inDoc', 'outDoc', 'pDoc', 'prDoc', 'qDoc'],
  inDoc: [RDOC_TYPE],
  outDoc: [MDOC_TYPE],
  pDoc: ['kDoc'],
  prDoc: ['tDoc'],
  qDoc: ['qsDoc']
};

export const CONTOL_DOC_RELATIONS = {
  RDOC_TYPE: [CONTROL_DOC_CDOC]
}

export const ACTIONS = {
  AddKey: 'AddKey',
  EditKey: 'EditKey',
  RemoveKey: 'RemoveKey',
  AddKeyVal: 'AddKeyVal',
  EditKeyVal: 'EditKeyVal',
  RemoveKeyVal: 'RemoveKeyVal',
  Add: 'Add',
  Edit: 'Edit',
  Remove: 'Remove'
};

export const FIELD_TYPES = {
  DEFAULT: 'Default',
  CUSTOM: 'Custom'
};

export const OBJECT_FIELD_TYPES = {
  OBJECT: 'Object',
  VALUES: 'Values'
}

export const DISPLAY_CONTROLLERS = {
  Textbox: 'Textbox',
  NumericBox: 'NumericBox',
  TextboxDisabled: 'TextboxDisabled',
  Dropdown: 'Dropdown',
  Date: 'Date',
  Grid: 'Grid',
  AutoCalc: 'AutoCalc',
  RecordSet: 'RecordSet',
  GridGroup: 'GridGroup',
  MonthlyGrid: 'MonthlyGrid',
  GroupedMonthlyGrid: 'GroupedMonthlyGrid',
  GroupedNuber: 'GroupedNuber'
};

export const INPUT_PROPS = {
  rDocNumberOfYears: {
    min: 0
  },
  mDocNumberOfYears: {
    min: 0
  },
  cDocNumberOfYears: {
    min: 0
  },
  bDocNumberOfYears: {
    min: 0
  },
  rDocTotal: {
    disabled: true
  },
  mDocTotal: {
    disabled: true
  },
  cDocTotal: {
    disabled: true
  },
  bDocTotal: {
    disabled: true
  },
  FieldName: {
    disabled: true
  }
};

export const YEAR_MONTHS_COUNT = 12;

export const MONTH_ABBR_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const DOC_TYPES = {
  xDocs: 'xDocs',
  ControlDocs: 'ControlDocs'
};

export const UOM_FORMATS = {
  CURRENCY: 'Currency',
  TIME: 'Time'
};

export const TITLES = {
  TOTAL: 'Total',
  YEAR: 'Year',
  PAYBACK: 'PayBack',
  ASSIGNTO: 'Assign To',
  IDEALIBRARY: 'Idea Library',
  CREATEDOCUMENT: 'Create New Document',
  CUSTOMFIELDS: 'CUSTOM FIELDS'
};

export const THEME_SIZES = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl'
};
