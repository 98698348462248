import { makeStyles } from "@material-ui/core";
import { THEME_SIZES } from './../constants';

const useTableResponsiveStyles = makeStyles((theme) => {
  return {
    root: (props = {}) => {
      const { columns, breakpoint, additionalStyles = {} } =  props;
      const resizeFunc = () => breakpoint ? theme.breakpoints.down(breakpoint) : theme.breakpoints.between(0, 1580);

      return {
        fontSize: '14px',
        '& th, td': {
          padding: '5px'
        },
        ...additionalStyles,
        [resizeFunc()]: {
          display: 'block',
          border: 'none',
          color: '#3b3e66',
          '& tbody:nth-child(odd)': {

          },
          '& tbody': {
            border: 'none !important'
          },
          '& thead': {
            display: 'none'
          },
          '& tbody,td,tr': {
            display: 'block'
          },
          '& tr': {
            margin: '0 0 1rem 0'
          },
          '& td:before': {
            background: '#f4f5fd',
            height: '100%',
            border: 'none',
            position: 'absolute',
            top: 0,
            left: '6px',
            width: '45%',
            padding: '5px',
            whiteSpace: 'nowrap'

          },
          '& td': {
            border: 'none',
            borderBottom: '1px solid #dcdef1',
            position: 'relative',
            paddingLeft: '50% !important'
          },
          ...columns.reduce((result, columnName, index) => ({
            ...result,
            [`& td:nth-of-type(${index+1}):before`]: { content: `"${columnName}"` }
          }), {})
        }
      }
    }
  }
});


export {
  useTableResponsiveStyles
}
