import React, { lazy, Suspense, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeProvider } from '@material-ui/styles';
import { MENU_ITEM_TYPES } from './constants';
import MuiTheme from './theme';
import { AuthService, Request } from './services';
import { setMenu, setPageTitle } from './reducers/ThemeOptions';

import { LeftSidebar, MinimalLayout } from './components/layout-blueprints';
import { PageTitle } from './components/layout-components';
import { Login } from './components/pages';
import { ProtectedRoute, SuspenseLoading } from './components/controls'
const PageRegisterOverlay = lazy(() => import('./components/pages/RegisterOverlay'));
const PageRecoverOverlay = lazy(() => import('./components/pages/RecoverOverlay'));
const PageError404 = lazy(() => import('./components/pages/Error404'));
const PageDocCreate = lazy(() => import('./components/pages/DocCreate'));
const PageDocDetails = lazy(() => import('./components/pages/DocDetails'));
const PageControlDocDetails = lazy(() => import('./components/pages/ControlDocsModify'));
const PageIdeaLibrary = lazy(() => import('./components/pages/IdeaLibrary'));
const PageCustomFields = lazy(() => import('./components/pages/CustomFields'));

const RenderComponent = ({ setPageTitle, Component, props }) => {
  const { menuProps: { label } = {} } = props;
  useEffect(() => {
    setPageTitle(label);
  }, []);

  return <Component {...props} />;
};

const prepareRouteSchema = (menus) => {
  const getMenuPaths = (result, item, parentItem) => {
    const isParentPath = parentItem && parentItem.Type === MENU_ITEM_TYPES.subMenu;

    return item.Type === MENU_ITEM_TYPES.link
      ? [
          ...result,
          {
            key: item.Id,
            path: isParentPath ? parentItem.Path : item.Path,
            component: lazy(
              () => import(`./components/pages/${isParentPath ? parentItem.Id : item.Id}`)
                .catch(() => ({
                  default: () =>
                    <>
                      <PageTitle
                        titleHeading={item.Label}
                        titleDescription="Page under development"
                      />
                    </>
                }))
            ),
            label: item.Label
          }
      ]
      : [...result, ...(item.Items || []).reduce((result, childItem) => getMenuPaths(result, childItem, item), [])];
  };

  return menus.reduce((result, { Items }) => [
    ...result,
    ...Items.reduce(getMenuPaths, [])
  ], [])
};

const Routes = (props ) => {
  const [prepareRoutes, setPrepareRoutes] = useState([]);
  const { setNavMenu, menu, setPageTitle, pageTitle } = props;
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const isAuthenticated = AuthService.isAuthenticated();
  useEffect(() => {
    if (isAuthenticated)
      Request.getMenus().then(menus => {
        const routes = prepareRouteSchema(menus);
        setPrepareRoutes(routes)
        setNavMenu(menus);
      });
  }, [isAuthenticated]);

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Route
              path={[
                '/login',
                '/signup',
                '/recover'
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route
                      exact
                      path="/login"
                      component={Login}
                    />
                    <Route
                      exact
                      path="/signup"
                      component={PageRegisterOverlay}
                    />
                    <Route
                      exact
                      path="/recover"
                      component={PageRecoverOverlay}
                    />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>

            <ProtectedRoute
              location={location}
              exact
              path={[
                ...prepareRoutes.map(({ path }) => path),
                '/docs/:docType/:docId',
                '/doc-create/:docType',
                '/doc-create/:docType/:parentDocId',
                '/doc-create',
                '/control-docs/:docType/:docId',
                '/idea-library',
                '/custom-fields'
              ]}
            >
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                  >
                    {
                      prepareRoutes.map(({ path, component: Component, key, ...otherProps }) => (
                        <Route
                          exact
                          key={key}
                          path={path}
                          component={
                            props => RenderComponent({
                              setPageTitle,
                              Component,
                              props: { ...props, menuProps: otherProps }
                            })
                          }
                        />
                      ))
                    }
                    <Route
                      exact
                      key="xdoc-create"
                      path="/doc-create/:docType"
                      component={PageDocCreate}
                    />
                    <Route
                      exact
                      key="xdoc-create-with-parent"
                      path="/doc-create/:docType/:parentDocId"
                      component={PageDocCreate}
                    />
                    <Route
                      exact
                      key="xdoc-create-idea-library"
                      path="/doc-create"
                      component={PageDocCreate}
                    />
                    <Route
                      exact
                      key="xdoc-details"
                      path="/docs/:docType/:docId"
                      component={PageDocDetails}
                    />
                    <Route
                      exact
                      key="ctrl-docs-details"
                      path="/control-docs/:docType/:docId"
                      component={PageControlDocDetails}
                    />
                    <Route
                      exact
                      key="idea-library"
                      path="/idea-library"
                      component={PageIdeaLibrary}
                    />
                    <Route
                      exact
                      key="custom-fields"
                      path="/custom-fields"
                      component={PageCustomFields}
                    />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </ProtectedRoute>
            <ProtectedRoute
              location={location}
            >
              <LeftSidebar>
                {
                  menu.length
                    ? <ProtectedRoute component={PageError404} />
                    : <ProtectedRoute component={SuspenseLoading} />
                }
              </LeftSidebar>
            </ProtectedRoute>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  menu: state.ThemeOptions.menu,
  pageTitle: state.ThemeOptions.pageTitle,
});

const mapDispatchToProps = (dispatch) => ({
  setNavMenu: (menu) => dispatch(setMenu(menu)),
  setPageTitle: (pageTitle) => dispatch(setPageTitle(pageTitle)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
