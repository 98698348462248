import React from 'react';
import { Button, MenuItem, TextField, Input, Checkbox, ListItemText } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { DISPLAY_CONTROLLERS, INPUT_PROPS } from './../../../constants';
import { getFirstDayOfMonth } from './../../../utils/date';
import { useFormStyles } from './../../../assets/styles';
import GridDetails from './../GridDetails';
import GridGroup from './../GridGroup';
import Calculations from './../Calculations';

export default ({
  item,
  fieldsType,
  selectedFieldItems,
  setSelectedFieldItems = () => {},
  toggleGridModal,
  handleGridChange,
  toggleGridGroupModal,
  docType,
  userHandleChange,
  classes = '',
  styles = {}
}) => {
  const formClasses = useFormStyles();

  const handleChange = !userHandleChange
    ? (e) => setSelectedFieldItems({
      ...selectedFieldItems,
      [fieldsType]: {
        ...selectedFieldItems[fieldsType],
        [item.field]: (e instanceof Date ? getFirstDayOfMonth(e) : e.target.value)
      }
    })
    : userHandleChange
  ;
  const key = fieldsType ? `${fieldsType} - ${item.field || item.label}` : (item.field || item.label);
  const value = fieldsType ? (selectedFieldItems[fieldsType] || {})[item.field] : selectedFieldItems[item.field];

  const dropDownComponent = () => (
    <TextField
      fullWidth
      select
      variant="outlined"
      className={`mb-3 ${formClasses.textbox} ${classes}`}
      key={key}
      value={value || ''}
      onChange={handleChange}
      label={item.label}
      SelectProps={{
        style: {
          ...styles
        }
      }}
    >
      <MenuItem key="" value=""><em>None</em></MenuItem>
      {
        item.source.map(({ Label, Value }) => (<MenuItem key={String(Value)} value={typeof Value === 'object' ? String(Label) : String(Value)}>{Label}</MenuItem>))
      }
    </TextField>
  );
  const TextboxComponent = ({ type = 'text', disabled = false } = {}) => {
    return (
      <TextField
        fullWidth
        variant="outlined"
        className={`mb-3 ${formClasses.textbox} ${classes}`}
        disabled={!!(INPUT_PROPS[item.field] || {}).disabled || disabled}
        type={type}
        label={item.label}
        key={key}
        value={value || ''}
        onChange={!disabled && !(INPUT_PROPS[item.field] || {}).disabled ? handleChange : e => e.preventDefault()}
        inputProps={{
          ...(INPUT_PROPS[item.field] || {}),
          disabled,
          style: {
            ...styles
          }
        }}
      />
    )
  };
  const MultipleSelect = () => {
    return (
      <TextField
        fullWidth
        select
        multiple
        className={formClasses.textbox}
        variant="outlined"
        label={item.label}
        value={item.resources || []}
        onChange={handleChange}
        input={<Input />}
        SelectProps={{
          style: {
            ...styles
          },
          multiple: true,
          renderValue: (selected) => item.label
        }}
      >
        {(item.allResources || []).map((resource) => {
          return (
            <MenuItem key={resource.id} value={resource.id}>
              <Checkbox checked={(item.resources || []).includes(resource.id)} />
              <ListItemText primary={resource.label} />
            </MenuItem>
          )
        })}
      </TextField>
    )
  };

  const DateComponent = () => (
    <MuiPickersUtilsProvider
      fullWidth
      utils={DateFnsUtils}
      key={key}
    >
      <KeyboardDatePicker
        fullWidth
        className={`mb-3 ${formClasses.textbox}`}
        inputVariant="outlined"
        id="date-picker-dialog"
        label={item.label}
        format="MM/yyyy"
        value={value}
        onChange={handleChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        views={["year", "month"]}
        inputProps={{
          style: {
            ...styles
          }
        }}
      />
    </MuiPickersUtilsProvider>
  );
  const gridGroupEl = () => !!toggleGridGroupModal
    ? <React.Fragment key={`${key}-grid-group-data`}></React.Fragment>
    : <GridGroup
      key={`${key}-grid-group-data`}
      label={item.label}
      groupData={value}
      docType={docType}
      selectedFieldItems={selectedFieldItems}
    />
  ;
  const component = {
    [DISPLAY_CONTROLLERS.Textbox]: TextboxComponent,
    [DISPLAY_CONTROLLERS.NumericBox]: () => TextboxComponent({ type: 'number' }),
    [DISPLAY_CONTROLLERS.AutoCalc]: () => TextboxComponent({ type: 'number', disabled: true }),
    [DISPLAY_CONTROLLERS.Dropdown]: dropDownComponent,
    // [DISPLAY_CONTROLLERS.RecordSet]: MultipleSelect,
    [DISPLAY_CONTROLLERS.RecordSet]: () => <></>,
    [DISPLAY_CONTROLLERS.Date]: DateComponent,
    [DISPLAY_CONTROLLERS.Grid]: () => !!toggleGridModal
      ? <Button fullWidth key={`${fieldsType} - ${item.field}-grid-data`} onClick={toggleGridModal} className="btn-neutral-first" style={{ height: '56px' }}>
        <span className="btn-wrapper--label">Show Grid Details</span>
      </Button>
      : <GridDetails
        key={`${key}-grid-data`}
        selectedFieldItems={selectedFieldItems}
        handleGridChange={handleGridChange}
        fieldsType={fieldsType}
        docType={docType}
      />,
    [DISPLAY_CONTROLLERS.GridGroup]: gridGroupEl,
    [DISPLAY_CONTROLLERS.MonthlyGrid]: gridGroupEl,
    [DISPLAY_CONTROLLERS.GroupedMonthlyGrid]: gridGroupEl,
    [DISPLAY_CONTROLLERS.GroupedNuber]: () => <Calculations
      key={`${key}-grid-group-data`}
      label={item.label}
      calculations={value}
    />
  }[item.displayController];

  return component ? component() : TextboxComponent();
};
