import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { setSidebarToggleMobile } from '../../../reducers/ThemeOptions';
import SidebarUserbox from '../SidebarUserbox';
import { MENU_BLOCKS, MENU_ITEM_TYPES } from '../../../constants';
import * as Icons from '@material-ui/icons';
const {
  ChevronRightTwoTone: ChevronRightTwoToneIcon,
  LinkTwoTone: LinkTwoToneIcon,
  WbIncandescentTwoTone,
  Settings: SettingsIcon,
} = Icons;

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile, sidebarUserbox, menu } = props;
  const { Items = [] } = menu.find(({ block = MENU_BLOCKS.leftSideBar }) => block === MENU_BLOCKS.leftSideBar) || {};
  const toggleSidebarMobile = () => setSidebarToggleMobile(false);

  const [opens, setOpens] = useState({});
  const toggleOpens = (label, event) => {
    setOpens({ ...opens, [label]: !opens[label] });
    event.preventDefault();
  };

  const renderLeftSideBar = (Items=[]) =>
    Items.map(item => {
      const Icon = item.Icon && Icons[item.Icon];

      return item.Type === MENU_ITEM_TYPES.link
        ? (
          <li key={item.Id}>
            <NavLink
              activeClassName="active"
              onClick={toggleSidebarMobile}
              className="nav-link-simple"
              exact
              to={item.Path}
            >
              {
                Icon &&
                <span className="sidebar-icon">
                  <Icon />
                </span>
              }
              {item.Label}
            </NavLink>
          </li>
        )
        : (
          <li key={item.Id}>
            <a
              href="#/"
              onClick={(e) => toggleOpens(item.Id, e)}
              className={clsx({ active: opens[item.Id] })}
            >
              {
                Icon &&
                <span className="sidebar-icon">
                  <Icon />
                </span>
              }
              <span className="sidebar-item-label">{item.Label}</span>
              <span className="sidebar-icon-indicator">
                <ChevronRightTwoToneIcon/>
              </span>
            </a>
            <Collapse in={opens[item.Id]}>
              <ul>
                {renderLeftSideBar(item.Items)}
              </ul>
            </Collapse>
          </li>
        )
      }
    );

  return (
    <>
      <PerfectScrollbar>
        {sidebarUserbox && <SidebarUserbox />}
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>Navigation menu</span>
          </div>
          <ul>
            {
              renderLeftSideBar(Items)
            }
            <li key='idea-llibrary'>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                exact
                to='/idea-library'
              >
                <span className="sidebar-icon">
                  <WbIncandescentTwoTone />
                </span>
                Idea Library
              </NavLink>
              <NavLink
                activeClassName="active"
                onClick={toggleSidebarMobile}
                className="nav-link-simple"
                exact
                to='/custom-fields'
              >
                <span className="sidebar-icon">
                  <SettingsIcon />
                </span>
                Custom Fields
              </NavLink>
            </li>
          </ul>
          <div className="sidebar-menu-box">
            <div className="sidebar-header">
              <span>Resources</span>
            </div>
            <ul>
              <li>
                <a
                  className="font-weight-normal"
                  href="https://foresidegroup.com/"
                  rel="noopener noreferrer"
                  target="_blank">
                  <span className="sidebar-icon">
                    <LinkTwoToneIcon />
                  </span>
                  <span className="sidebar-item-label">Web Site</span>
                </a>
              </li>
              <li>
                <a
                  className="font-weight-normal"
                  href="https://docs.uifort.com/bamburgh-react-admin-dashboard-pro-docs"
                  target="_blank"
                  rel="noopener noreferrer">
                  <span className="sidebar-icon">
                    <LinkTwoToneIcon />
                  </span>
                  <span className="sidebar-item-label">Documentation</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  menu: state.ThemeOptions.menu
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
