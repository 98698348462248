import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Container,
  InputAdornment,
  Card,
  Button,
  List,
  ListItem,
  TextField
} from '@material-ui/core';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';

import particles3 from '../../../assets/images/hero-bg/particles-3.svg';
import { Request } from '../../../services';
import { Redirect } from 'react-router';
import Alert from '@material-ui/lab/Alert/Alert';

export default function Recover() {
  console.log('render: ')

  const [redirect, setRedirect] = useState('');
  const [errors, setErrors] = useState([]);
  const [fields, setFields] = useState({});

  const handleSelectChange = useCallback((type) => ({
    [type]: (value) => {
      console.log('value: ', value.target.value)

      setFields({ ...fields, [type]: value.target.value })
    }
  }[type]),[JSON.stringify(fields)]);

  const handleSubmit = useCallback(() => {
    setErrors([]);

    Request.recover(fields)
      .then(() => {
        console.log('letter sent')
      })
      .catch((error) => {
        setErrors(error.errors || []);
      });
  }, [JSON.stringify(fields)]);

  if (redirect) return (<Redirect to={redirect}/>);

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="hero-wrapper w-100 bg-composed-wrapper bg-second min-vh-100">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div
              className="bg-composed-wrapper--image opacity-6"
              style={{ backgroundImage: 'url(' + particles3 + ')' }}
            />
            <div className="bg-composed-wrapper--bg bg-light-pure opacity-2" />
            <div className="bg-composed-wrapper--content p-3 p-md-5">
              <Container>
                <Card className="rounded-sm modal-content p-3 bg-white-10">
                  <Card className="rounded-sm overflow-hidden shadow-xxl font-size-sm p-3 p-sm-0">
                    <Grid container spacing={0}>
                      <Grid
                        item
                        lg={12}
                        className="d-flex align-items-center justify-content-center flex-column">
                        <div className="divider-v divider-v-lg d-none d-lg-block" />
                        <div className="text-center mt-5">
                          <h1 className="display-4 mb-1 font-weight-bold">
                            Recover Password
                          </h1>
                          <p className="font-size-lg mb-0 text-black-50">
                            Forgot your password? No worries, we're here to
                            help!
                          </p>
                          <div className="text-center mb-4 py-4">
                            <TextField
                              fullWidth
                              variant="outlined"
                              id="textfield-email"
                              label="Email address"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <MailOutlineTwoToneIcon />
                                  </InputAdornment>
                                )
                              }}
                              onChange={handleSelectChange('email')}
                              value={fields.email || ''}
                            />
                            {
                              errors.map((error, index) => (
                                <Alert
                                  key={index}
                                  className="mb-4"
                                  severity="error">
                                  {error}
                                </Alert>
                              ))
                            }
                            <Button
                              fullWidth
                              className="text-uppercase font-weight-bold font-size-sm mt-4 btn-primary"
                              onClick={handleSubmit}
                            >
                              Send password
                            </Button>
                            <div className="text-center text-black-50 mt-3">
                              Already have an account? &nbsp;
                              <a
                                className="text-first"
                                href="/login"
                              >
                                Login
                              </a>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </Card>
              </Container>
            </div>
          </div>
          <div className="hero-footer w-100 pb-4">
            <Container>
              <div className="py-3 d-block d-lg-flex font-size-xs justify-content-between">
                <div className="text-center d-block mb-3 mb-md-0 text-white">
                  Copyright &copy; 2021 - Foresidegroup.com
                </div>
                <List
                  component="div"
                  className="nav-transparent text-nowrap d-flex justify-content-center">
                  <ListItem
                    button
                    className="text-white-50"
                    href="#/"
                    onClick={(e) => e.preventDefault()}>
                    Privacy Policy
                  </ListItem>
                  <ListItem
                    button
                    className="text-white-50"
                    href="#/"
                    onClick={(e) => e.preventDefault()}>
                    Terms of Service
                  </ListItem>
                </List>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
