import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  InputLabel,
  InputAdornment,
  Card,
  MenuItem,
  Button,
  Tooltip,
  TextField,
  FormControl,
  Select,
  Link, Dialog
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';
import { Request } from '../../../services';
import { ACTIONS, IDOC_TYPE, SDOC_TYPE, TITLES } from '../../../constants';
import { useTableResponsiveStyles } from '../../../theme/styles';
import { setPageTitle } from '../../../reducers/ThemeOptions';
import { connect } from 'react-redux';

let i = 0;
const dataGridSchema = [
  {
    label: '#',
    render: ({ doc, index: i }) => (<td key={i + 1} className="text-center">{i + 1}</td>)
  },
  {
    label: 'Name',
    key: `${IDOC_TYPE}Name`
  },
  {
    label: 'Description',
    key: `${IDOC_TYPE}LongDescr`
  },
  {
    label: 'Gate',
    key: `${IDOC_TYPE}Gate`
  },
  {
    label: 'Actions',
    key: '',
    render: ({ doc, index: i, history, setRemoveFunc, createRemoveFunc, toggleModal }) => {
      return (<td key="actions" className="text-center">
        <Button
          className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          onClick={(e) => {
            e.preventDefault();
            history.push(`/docs/${IDOC_TYPE}/${doc[`${IDOC_TYPE}Id`]}`);
          }}
        >
          <FontAwesomeIcon
            icon={['far', 'edit']}
            className="font-size-sm"
          />
        </Button>
        <Button
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
          onClick={(e) => {
            e.preventDefault();
            setRemoveFunc(createRemoveFunc(doc[`${IDOC_TYPE}Id`], IDOC_TYPE));
            toggleModal();
          }}
        >
          <FontAwesomeIcon
            icon={['fas', 'times']}
            className="font-size-sm"
          />
        </Button>
      </td>)
    }
  }
];

const IdeaLibrary = (props) => {
  const history = useHistory();
  const { setPageTitle, match: { params: { docType } = {} } = {} } = props;
  const [docs, setDocs] = useState([]);
  const [removeDoc, setRemoveDoc] = useState();
  const [schema, setSchema] = useState(dataGridSchema);
  const [removeFunc, setRemoveFunc] = useState(() => {});
  const [openModal, setOpenModal] = useState(false);

  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const tableResponsiveStyles = useTableResponsiveStyles({
    columns: dataGridSchema.map(({ label }) => label),
    additionalStyles: {
      '& th, td': {
        padding: '5px',
        minHeight: '35px'
      }
    }
  });

  const createRemoveFunc = (id, type) => () => () => {
    toggleModal();

    return Request.removeDoc('iDocs', id)
      .then(() => {
        Request.getDocs()
          .then((docs) => {
            const nextDocs = (docs[IDOC_TYPE] || []).filter(({
              [`${IDOC_TYPE}Id`]: Id,
              [`${SDOC_TYPE}Id`]: parentId }) => !parentId || Id === id
            )
            setDocs(nextDocs);
          });
      });
  };

  useEffect(() => {
    setPageTitle(TITLES.IDEALIBRARY)
    Promise.all([Request.getDocs()])
      .then(([docs = {}]) => {
        const nextDocs = (docs[IDOC_TYPE] || []).filter(({ [`${SDOC_TYPE}Id`]: parentId }) => !parentId)
        setDocs(nextDocs);
      });
  }, []);

  return (
    <>
      <Card className="card-box mb-spacing-6-x2" style={{ borderRadius: 0 }}>
          <div className="d-flex justify-content-between px-4 py-3">
            <div className="search-wrapper">
              <TextField
                variant="outlined"
                size="small"
                id="input-search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
              />
            </div>

          <div className="card-header--actions">
            <Button
              size="small"
              className="btn-neutral-primary"
              href={'/doc-create/'}
            >
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'plus-circle']} />
              </span>
              <span className="btn-wrapper--label">Add {docType}</span>
            </Button>
          </div>
        </div>
        <div className="divider" />
        <div className="table-responsive-md">
          <Table className={`table table-hover text-nowrap mt-1 ${tableResponsiveStyles.root} clear-both`}>
            <thead>
            <tr>
              { schema.map(({ label }, index) => (<th key={`${index}-th`} className="bg-white text-center">{label}</th>)) }
            </tr>
            </thead>
            <tbody>
            {
              docs
                .map((doc, index) => (
                    <tr key={index}>
                      {
                        dataGridSchema.map(({ key, render }, subIndex) => render
                          ? render({ doc, index, history, setRemoveFunc, createRemoveFunc, toggleModal })
                          : (<td key={`${index + 1}-${subIndex}-td`} className="text-center">{doc[key]}</td>)
                        )
                      }
                    </tr>
                  )
                )
            }
            </tbody>
          </Table>
        </div>
      </Card>
      <Dialog open={openModal} onClose={toggleModal} classes={{ paper: 'shadow-lg rounded' }}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon icon={['fas', 'times']} className="d-flex align-self-center display-3"/>
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">Are you sure you want to delete this document?</h4>
          <p className="mb-0 font-size-lg text-muted">You cannot undo this operation.</p>
          <div className="pt-4">
            <Button onClick={toggleModal} className="btn-neutral-secondary btn-pill mx-1">
              <span className="btn-wrapper--label"> Cancel </span>
            </Button>
            <Button
              onClick={() => { removeFunc(); toggleModal(); } }
              className="btn-danger mx-1"
            >
              <span className="btn-wrapper--label"> Delete </span>
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

const mapStateToProps = (state) => ({
  pageTitle: state.ThemeOptions.pageTitle
});

const mapDispatchToProps = (dispatch) => ({
  setPageTitle: (pageTitle) => dispatch(setPageTitle(pageTitle))
});

export default connect(mapStateToProps, mapDispatchToProps)(IdeaLibrary);
