import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Table,
  InputLabel,
  InputAdornment,
  Card,
  MenuItem,
  Button,
  Tooltip,
  TextField,
  FormControl,
  Select
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import avatar1 from '../../../assets/images/avatars/avatar1.jpg';
import avatar2 from '../../../assets/images/avatars/avatar2.jpg';
import avatar3 from '../../../assets/images/avatars/avatar3.jpg';
import avatar4 from '../../../assets/images/avatars/avatar4.jpg';
import avatar5 from '../../../assets/images/avatars/avatar5.jpg';
import avatar6 from '../../../assets/images/avatars/avatar6.jpg';
import avatar7 from '../../../assets/images/avatars/avatar7.jpg';
import SearchTwoToneIcon from '@material-ui/icons/SearchTwoTone';

const dataGridSchema = [
  {
    label: 'First Name',
    value: 'firstName'
  },
  {
    label: 'Last Name',
    value: 'lastName'
  },
  {
    label: 'Email',
    value: 'email'
  },
  {
    label: 'Phone',
    value: 'phone'
  },
  {
    label: 'Role',
    value: 'role'
  }
];

export default function Tickets() {
  const [entries, setEntries] = useState('1');
  const handleChange = (event) => {
    setEntries(event.target.value);
  };

  return (
    <>
      <Card className="card-box mb-spacing-6-x2">
        <div className="card-header py-3">
          <div className="card-header--title font-size-lg">Support board</div>
          <div className="card-header--actions">
            <Button size="small" className="btn-neutral-primary">
              <span className="btn-wrapper--icon">
                <FontAwesomeIcon icon={['fas', 'plus-circle']} />
              </span>
              <span className="btn-wrapper--label">Add ticket</span>
            </Button>
          </div>
        </div>
        <div className="d-flex justify-content-between px-4 py-3">
          <div className="d-flex align-items-center">
            <span>Show</span>
            <FormControl size="small" variant="outlined" className="mx-3">
              <InputLabel id="select-entries-label">Entries</InputLabel>
              <Select
                labelId="select-entries-label"
                id="select-entries"
                value={entries}
                onChange={handleChange}
                label="Entries">
                <MenuItem className="mx-2" value={1}>
                  All entries
                </MenuItem>
                <MenuItem className="mx-2" value={10}>
                  10
                </MenuItem>
                <MenuItem className="mx-2" value={15}>
                  15
                </MenuItem>
                <MenuItem className="mx-2" value={20}>
                  20
                </MenuItem>
                <MenuItem className="mx-2" value={25}>
                  25
                </MenuItem>
                <MenuItem className="mx-2" value={30}>
                  30
                </MenuItem>
              </Select>
            </FormControl>
            <span>entries</span>
          </div>
          <div className="search-wrapper">
            <TextField
              variant="outlined"
              size="small"
              id="input-search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>
        </div>
        <div className="divider" />
        <div className="table-responsive-md">

        </div>
        <div className="card-footer py-3 d-flex justify-content-between">
          <Pagination
            className="pagination-second"
            variant="outlined"
            count={10}
          />
          <div className="d-flex align-items-center">
            <span>Show</span>
            <FormControl size="small" variant="outlined" className="mx-3">
              <InputLabel id="select-entries-label">Entries</InputLabel>
              <Select
                labelId="select-entries-label"
                id="select-entries"
                value={entries}
                onChange={handleChange}
                label="Entries">
                <MenuItem className="mx-2" value={1}>
                  All entries
                </MenuItem>
                <MenuItem className="mx-2" value={10}>
                  10
                </MenuItem>
                <MenuItem className="mx-2" value={15}>
                  15
                </MenuItem>
                <MenuItem className="mx-2" value={20}>
                  20
                </MenuItem>
                <MenuItem className="mx-2" value={25}>
                  25
                </MenuItem>
                <MenuItem className="mx-2" value={30}>
                  30
                </MenuItem>
              </Select>
            </FormControl>
            <span>entries</span>
          </div>
        </div>
      </Card>
    </>
  );
}
