import { makeStyles } from "@material-ui/core";
import { THEME_SIZES } from './../../../constants';

const useChartStyles = makeStyles((theme) => {
  return {
    root: (props = {}) => {
      const { breakpoint = THEME_SIZES.sm } =  props;

      return {
        width: '100%',
        [theme.breakpoints.down(breakpoint)]: {
          display: 'none'
        }
      }
    }
  }
});

export {
  useChartStyles
}
