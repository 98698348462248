import React from 'react';
import { SvgIcon } from '@material-ui/core';

export default (props) =>
  (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14, position: 'relative', top: '-2px', right: '-2px' }} {...props}>
      {/*<path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />*/}
      <circle cx="14" cy="14" r="5" fill="rgba(0, 0, 0, 0.54)" />
    </SvgIcon>
  );
