import React, { useState, useCallback, useEffect } from 'react';
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  Container,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Card,
  Button,
  List,
  ListItem,
  TextField
} from '@material-ui/core';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import Alert from '@material-ui/lab/Alert';

import hero6 from '../../../assets/images/hero-bg/hero-1.jpg';
import { AuthService } from '../../../services'

export default () => {
  const [checked, setChecked] = useState(true);
  const [redirect, setRedirect] = useState('');
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState([
    // "The User ID and/or password combination are incorrect."
  ]);
  const isAuthenticated = AuthService.isAuthenticated();

  const handleSelectChange = useCallback((type) => ({
    [type]: (value) => {
      setFields({ ...fields, [type]: value.target.value });
    }
  }[type]),[JSON.stringify(fields)]);

  const handleChange = useCallback((event) => {
    setChecked(event.target.checked);
  });

  const handleSubmit = useCallback(() => {
    setErrors([]);

    AuthService.login(fields)
      .then(user => {
        if (user.accessToken) setRedirect('/');
      })
      .catch((error) => {
        setErrors(error.errors || ['The User email and/or password combination are incorrect.']);
      });
  },[JSON.stringify(fields)]);

  useEffect(() => {
    if (isAuthenticated) setRedirect('/');
  }, [isAuthenticated]);

  if (redirect) return (<Redirect to={redirect}/>);

  return (
    <>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="hero-wrapper w-100 bg-composed-wrapper bg-midnight-bloom min-vh-100">
          <div className="flex-grow-1 w-100 d-flex align-items-center">
            <div
              className="bg-composed-wrapper--image opacity-6"
              style={{ backgroundImage: 'url(' + hero6 + ')' }}
            />
            <div className="bg-composed-wrapper--bg bg-second opacity-7" />
            <div className="bg-composed-wrapper--content p-3 p-md-5">
              <Container>
                <Card className="rounded-sm modal-content p-3 bg-white-10">
                  <Card className="rounded-sm overflow-hidden shadow-xxl font-size-sm p-3 p-sm-0">
                    <Grid container spacing={0}>
                      <Grid
                        item
                        lg={12}
                        className="d-flex align-items-center justify-content-center flex-column">
                        <div className="divider-v divider-v-lg d-none d-lg-block" />
                        <div className="text-center mt-4">
                          <h1 className="font-size-xxl mb-1 font-weight-bold">
                            Login
                          </h1>
                          <p className="mb-0 text-black-50">
                            Fill in the fields below to login to your account
                          </p>
                        </div>
                        <div className="py-4">
                          <div className="text-center mb-3">
                            <Button
                              className="m-2 btn-pill px-4 font-weight-bold btn-google"
                              size="small">
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={['fab', 'google']} />
                              </span>
                              <span className="btn-wrapper--label">
                                Login with Google
                              </span>
                            </Button>
                            <Button
                              className="m-2 btn-pill px-4 font-weight-bold btn-facebook"
                              size="small">
                              <span className="btn-wrapper--icon">
                                <FontAwesomeIcon icon={['fab', 'facebook']} />
                              </span>
                              <span className="btn-wrapper--label">
                                Login with Facebook
                              </span>
                            </Button>
                          </div>
                          <div className="text-center text-black-50 py-2 mb-4">
                            or sign in with credentials
                          </div>
                          <div>
                            <div className="mb-4">
                              <TextField
                                fullWidth
                                variant="outlined"
                                id="textfield-email"
                                label="Email address"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <MailOutlineTwoToneIcon />
                                    </InputAdornment>
                                  )
                                }}
                                onChange={handleSelectChange('email')}
                                value={fields.email || ''}
                              />
                            </div>
                            <div className="mb-3">
                              <TextField
                                fullWidth
                                variant="outlined"
                                id="textfield-password"
                                label="Password"
                                type="password"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <LockTwoToneIcon />
                                    </InputAdornment>
                                  )
                                }}
                                onChange={handleSelectChange('password')}
                                value={fields.password || ''}
                              />
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={checked}
                                    onChange={handleChange}
                                    value="checked"
                                    color="primary"
                                  />
                                }
                                className="font-size-md"
                                label="Remember me"
                              />
                              <div>
                                <a
                                  className="text-first"
                                  href="/recover"
                                >
                                  Recover password
                                </a>
                              </div>
                            </div>
                            {
                              errors.map((error, index) => (
                                  <Alert
                                    key={index}
                                    className="mb-4"
                                    severity="error">
                                    {error}
                                  </Alert>
                              ))
                            }
                            <div className="text-center py-4">
                              <Button
                                className="btn-second font-weight-bold w-50 my-2"
                                onClick={handleSubmit}
                              >
                                Sign in
                              </Button>
                            </div>
                            <div className="text-center text-black-50 mt-3">
                              Don't have an account?{' '}
                              <a
                                className="text-first"
                                href="/signup"
                              >
                                Sign up
                              </a>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </Card>
              </Container>
            </div>
          </div>
          <div className="hero-footer w-100 pb-4">
            <Container>
              <div className="py-3 d-block d-lg-flex font-size-xs justify-content-between">
                <div className="text-center d-block mb-3 mb-md-0 text-white">
                  Copyright &copy; 2021 - Foresidegroup.com
                </div>
                <List
                  component="div"
                  className="nav-transparent text-nowrap d-flex justify-content-center">
                  <ListItem
                    button
                    className="text-white-50"
                    href="#/"
                    onClick={(e) => e.preventDefault()}>
                    Privacy Policy
                  </ListItem>
                  <ListItem
                    button
                    className="text-white-50"
                    href="#/"
                    onClick={(e) => e.preventDefault()}>
                    Terms of Service
                  </ListItem>
                </List>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
