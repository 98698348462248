import React from 'react';

import clsx from 'clsx';

import { connect } from 'react-redux';

import { setSidebarToggleMobile } from '../../../reducers/ThemeOptions';

import HeaderDots from '../HeaderDots';
import HeaderDrawer from '../HeaderDrawer';
import HeaderUserbox from '../HeaderUserbox';
import HeaderSearch from '../HeaderSearch';
import HeaderMenu from '../HeaderMenu';
import { Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    pageTitle,
    pageButtonsProps
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <div
      className={clsx('app-header', {
        'app-header--shadow': headerShadow,
        'app-header--opacity-bg': headerBgTransparent
      })}>
      <div className="app-header--pane w-100">
        <button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
        <div className="font-weight-bold ml-4 mr-4 border-right nowrap">
          <div className="pt-5px">{pageTitle}</div>
        </div>
        <div className="d-inline-block w-100">
          <div className="float-left mr-2 mt-5px border-right">
            {
              pageButtonsProps?.selectedItem &&
                <Button
                  size="small"
                  className="btn-neutral-primary mr-2"
                  onClick={(event) => {
                    event.preventDefault();
                    pageButtonsProps.onAdd(pageButtonsProps.selectedDoc.docType);
                  }}
                >
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'plus-circle']}/>
                </span>
                  <span className="btn-wrapper--label">{pageButtonsProps.selectedDoc.title}</span>
                </Button>
            }
            {
              !!(pageButtonsProps?.childTypes || []).length &&
                pageButtonsProps.childTypes.map(({ docType, title }) => (
                  <Button
                    key={docType}
                    size="small"
                    className="btn-neutral-primary mr-2"
                    onClick={(event) => {
                      event.preventDefault();
                      pageButtonsProps.onAdd(docType);
                    }}
                  >
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'plus-circle']} />
                    </span>
                    <span className="btn-wrapper--label">{title}</span>
                  </Button>
                ))
            }
          </div>

          {
            pageButtonsProps?.isShowEditBtns &&
              <div className="float-right mr-100px">
                <Button
                  onClick={pageButtonsProps.handleFieldsCancel}
                  className="btn-neutral-secondary text-danger mr-2 b-1 w-80px">
                  <span className="btn-wrapper--label">Discard</span>
                </Button>
                <Button
                  onClick={pageButtonsProps.handleFieldsSave}
                  className="btn-primary mr-2 w-80px">
                  <span className="btn-wrapper--label">Save</span>
                </Button>
              </div>
          }
        </div>
      </div>
      <div className="app-header--pane">
        <HeaderDots />
        <HeaderUserbox />
        {/*<HeaderDrawer />*/}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  pageTitle: state.ThemeOptions.pageTitle,
  pageButtonsProps: state.ThemeOptions.pageButtonsProps
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
