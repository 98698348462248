import { makeStyles } from '@material-ui/core/styles';

const useTreeStyles = makeStyles({
  root: {
    flexGrow: 1
  },
  actionBtn: {
    opacity: '0',
    backgroundColor: '#ffffff',
    padding: '4px',
    position: 'relative',
    margin: '0 2px',
    zIndex: 100
  }
});

const useHideBorderStyles = makeStyles(() => ({
  root: {
    borderRadius: 0,
  },
}));

const useFormStyles = makeStyles((theme) => ({
  textbox: {
    background: theme.palette.yellow.main,
    '& .MuiOutlinedInput-input': {
      padding: '14px'
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 16px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)'
    }
  }
}));

const useTableBlockStyles = makeStyles({
  rootElement: {
    borderRadius: 0,
    padding: '0px !important',
    width: '100%',
    display: 'table',
    tableLayout: 'fixed'
  },
  navCellElement: {
    padding: 0,
    display: 'table-cell',
    width: '300px',
    whiteSpace: 'nowrap',
    background: 'linear-gradient(to right, #fcfcfb, #dedfdf)',
    height: '100%'
  },
  cellElement: {
    padding: '0px !important',
    display: 'table-cell'
  },
});

export {
  useTreeStyles,
  useHideBorderStyles,
  useFormStyles,
  useTableBlockStyles
}
