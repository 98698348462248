import fastDeepEqual from 'fast-deep-equal';
import clsx from 'clsx';
import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Delete as DeleteIcon,
  Close as CloseIcon
}  from '@material-ui/icons';
import { TreeView } from '@material-ui/lab';

import {
  Card,
  MenuItem,
  Button,
  TextField,
  Dialog,
  Grid,
  CardContent,
  DialogTitle,
  IconButton,
  Typography,
  DialogContent,
  DialogActions,
  List,
  ListItem
} from '@material-ui/core';

import {
  DISPLAY_CONTROLLERS,
  FIELD_TITLES,
  GDOC_TYPE,
  TITLES,
  CONFIG_TYPES,
  CONFIG_FIELDS
} from '../../../constants';
import { setPageButtonsProps, setPageTitle } from '../../../reducers/ThemeOptions';
import { Request } from '../../../services';

import { useTreeStyles, useFormStyles, useTableBlockStyles } from './../../../assets/styles';
import { useDialogStyles } from '../SystemSettings/styles';
import { CloseSquare, MinusSquare, PlusSquare } from './../../icons';

import { RenderTreeItem, RenderField } from './../../controls';

const READ_ONLY_FIELDS = [FIELD_TITLES.Aggregations, FIELD_TITLES.Calculations];
const DISPLAY_CONTROLLERS_VALUES = Object.values(DISPLAY_CONTROLLERS);

const setTree = (configs) =>
  Object.keys(CONFIG_TYPES).reduce((result, configType) => [
    ...result,
    ...Object.keys(configs.baseConfig[configType])
      .map(docType => ({
        configType: configType,
        nodeId: docType,
        docType: docType,
        baseClientName: configs.baseConfig[configType][docType].DocumentType || docType,
        clientName: configs?.clientConfig?.[configType]?.[docType]?.DocumentType ||
          configs.baseConfig[configType][docType].DocumentType ||
          docType
      }))
  ], []);

const CustomFields = (props) => {
  const { setPageTitle, setPageButtonsProps } = props;
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [configs, setConfigs] = useState({});
  const [defaultConfigs, setDefaultConfigs] = useState({});
  const [types, setTypes] = useState([]);
  const [docExpanded, setDocExpanded] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(GDOC_TYPE);
  const [openAddFieldModal, setOpenAddFieldModal] = useState(false);
  const [selectedAddField, setSelectedAddField] = useState({});
  const [activeTab, setActiveTab] = useState(FIELD_TITLES.Fields);

  const tableBlockStyles = useTableBlockStyles();
  const classes = useTreeStyles();
  const DialogStyles = useDialogStyles();
  const formClasses = useFormStyles();

  const selectedType = types.find(({ nodeId }) => nodeId === selectedDoc);

  const toggleTab = useCallback((tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  }, [activeTab]);

  const handleSaveAddField = useCallback(() => {
    console.log('[handleSaveAddField] selectedAddField: ', selectedAddField);
    const nextConfigs = {
      ...configs,
      clientConfig: {
        ...(configs.clientConfig || {}),
        [selectedAddField.type]: {
          ...(configs.clientConfig[selectedAddField.type] || {}),
          [selectedAddField.docType]: {
            ...(configs.clientConfig[selectedAddField.type]?.[selectedAddField.docType] || {}),
            [selectedAddField.fieldsType]: [
              ...(configs.clientConfig[selectedAddField.type]?.[selectedAddField.docType]?.[selectedAddField.fieldsType] || []),
              {
                FieldName: `${selectedDoc}${selectedAddField.FieldName}`,
                Label: selectedAddField.Label,
                DisplayController: selectedAddField.DisplayController,
                DisplayPosition: selectedAddField.DisplayPosition,
                ...(
                  !!selectedAddField.Source
                    ? {
                        SourceType: 'Collection',
                        SourceAtributes: {
                          Collection: "SystemFields",
                          Version: "1.0.0",
                          FieldName: `${selectedAddField.Source.split(':').shift()}.${selectedAddField.Source.split(':').pop()}`
                        }
                    }
                    : {}
                )
              }
            ]
          }
        }
      }
    };

    setConfigs(nextConfigs);
    setOpenAddFieldModal(false);
  }, [configs, selectedAddField]);

  const handleDeleteField = useCallback(({ configType, docType, fieldsType, field }) => {
    const nextConfigs = {
      ...configs,
      clientConfig: {
        ...configs.clientConfig,
        [configType]: {
          ...configs.clientConfig[configType],
          [docType]: {
            ...configs.clientConfig[configType][docType],
            [fieldsType]: [
              ...(configs.clientConfig[configType][docType]?.[fieldsType] || []).filter(({ FieldName }) => FieldName !== field.FieldName),
            ]
          }
        }
      }
    };

    setConfigs(nextConfigs);
  }, [configs]);

  const handleChangeAddField = useCallback((objField) => {
    setSelectedAddField({ ...selectedAddField, ...objField });
  }, [JSON.stringify(selectedAddField)]);

  const toggleAddFieldModal = useCallback(() => {
    setOpenAddFieldModal(!openAddFieldModal);
  }, [openAddFieldModal]);

  const toggleModal = useCallback(() => {
    setOpenModal(!openModal);
  }, [openModal]);

  const getFieldValue = useCallback(({ baseField, type, docType, fieldsType, fieldName }) => {
    const field = (configs.clientConfig?.[type]?.[docType]?.[fieldsType] || [])
      .find(({ FieldName }) => FieldName === baseField.FieldName) || {};

    return field[fieldName];
  }, [configs]);

  const handleChange = useCallback(({ type, docType, fieldsType, fieldName, event, baseField }) => {
    const field = (configs.clientConfig?.[type]?.[docType]?.[fieldsType] || [])
      .find(({ FieldName }) => FieldName === baseField.FieldName) || {};
    const nextConfigs = {
      ...configs,
      clientConfig: {
        ...configs.clientConfig,
        [type]: {
          ...configs.clientConfig[type],
          [docType]: {
            ...configs.clientConfig[type][docType],
            [fieldsType]: [
              ...(configs.clientConfig[type][docType]?.[fieldsType] || []).filter(({ FieldName }) => FieldName !== baseField.FieldName),
              {
                ...baseField,
                ...field,
                FieldName: baseField.FieldName,
                [fieldName]: event.target.value
              }
            ]
          }
        }
      }
    };

    setConfigs(nextConfigs);
  }, [configs]);

  const handleTitle = useCallback((event) => {
    const nextConfigs = {
      ...configs,
      clientConfig: {
        ...configs.clientConfig,
        [selectedType.configType]: {
          ...configs.clientConfig[selectedType.configType],
          [selectedType.docType]: {
            ...configs.clientConfig[selectedType.configType][selectedType.docType],
            DocumentType: event.target.value
          }
        }
      }
    };

    setConfigs(nextConfigs);
    setTypes(setTree(nextConfigs));
  }, [configs, selectedType]);

  const handleToggle = useCallback((event, nodeIds) => {
    setDocExpanded(nodeIds);
  }, []);
  const handleDocSelect = useCallback((event, nodeId) => {
    setSelectedDoc(nodeId);
  }, []);

  useEffect(() => {
    setPageButtonsProps({
      isShowEditBtns: !fastDeepEqual(defaultConfigs?.clientConfig || {}, configs?.clientConfig || {}),
      childTypes: activeTab ? [{ docType: activeTab, title: activeTab }] : [],
      onAdd: (fieldsType) => {
        setSelectedAddField({ type: selectedType.configType, docType: selectedType.docType, fieldsType });
        setOpenAddFieldModal(true);
      },
      handleFieldsCancel: () => {
        console.log('defaultConfigs: ', defaultConfigs)
        setConfigs(defaultConfigs);
        setTypes(setTree(defaultConfigs));
      },
      handleFieldsSave: () => {
        const isUpdate = !!defaultConfigs?.clientConfig;
        console.log('isUpdate: ', isUpdate)
        const { _id, ...nextData } = configs?.clientConfig || {}
        Request[!isUpdate ? 'createClientConfiguration' : 'updateClientConfiguration'](nextData).then(() => {
          setDefaultConfigs(configs)
        });
      }
    });
  }, [defaultConfigs, configs, activeTab, selectedType]);


  useEffect(() => {
    setPageTitle(TITLES.CUSTOMFIELDS);
    Promise.all([Request.getConfiguration()])
      .then(([configs = {}]) => {
        console.log('configs: ', configs);
        setDefaultConfigs(configs)
        setConfigs(configs);
        setLoading(false);

        setTypes(setTree(configs));
      });
  }, []);

  return (
    <>
      <Card className={`shadow-xxl ${tableBlockStyles.rootElement}`}>
          {
            !loading &&
              <CardContent className={`scroll-bar px-2 py-2 ${tableBlockStyles.navCellElement}`}>
                <TreeView
                  className={classes.root}
                  defaultCollapseIcon={<MinusSquare />}
                  defaultExpandIcon={<PlusSquare />}
                  defaultEndIcon={<CloseSquare />}
                  expanded={docExpanded}
                  selected={selectedDoc}
                  onNodeToggle={handleToggle}
                  onNodeSelect={handleDocSelect}
                >
                  {
                    types.map(item => <RenderTreeItem key={item.nodeId} { ...{ item } } />)
                  }
                </TreeView>
            </CardContent>
          }
          <CardContent className={`${tableBlockStyles.cellElement}`}>
            {
              !loading &&
              <div className="w-100 background-yellow p-10-0">
                {
                  Object.keys(CONFIG_TYPES).map(configType =>
                    Object.keys(configs.baseConfig[configType])
                      .filter(docType => docType === selectedDoc)
                      .map(docType => (
                        <span key={configType} className="font-weight-bold font-size-lg pl-5">
                            {configs?.clientConfig?.[configType]?.[selectedDoc]?.DocumentType || configs.baseConfig[configType][selectedDoc]?.DocumentType} Configuration
                          </span>
                      ))
                  )
                }
              </div>
            }
            <List className="nav-tabs nav-tabs d-flex justify-content-left w-100 " style={{ background: '#fdeab2' }}>
              {
                !loading &&
                  <>
                    {
                      Object.keys(FIELD_TITLES)
                        .filter(fieldsType => !READ_ONLY_FIELDS.includes(fieldsType))
                        .map(fieldsType => (
                          <ListItem
                            key={fieldsType}
                            className="text-center"
                            style={{ borderRadius: 0 }}
                            button
                            disableRipple
                            selected={activeTab === fieldsType}
                            onClick={() => {
                              toggleTab(fieldsType);
                            }}
                          >
                            <span className="font-weight-bold text-uppercase font-size-sm">
                              {FIELD_TITLES[fieldsType]}
                            </span>
                          </ListItem>
                        ))
                    }
                  </>
              }
            </List>
            {
              !loading && Object.keys(CONFIG_TYPES).map(configType =>
              Object.keys(configs.baseConfig[configType])
                .filter(docType => docType === selectedDoc)
                .map(docType => (
                  <React.Fragment key={docType}>
                    {
                      Object.keys(FIELD_TITLES)
                        .filter(fieldsType => !READ_ONLY_FIELDS.includes(fieldsType))
                        .map(fieldsType => (
                          <div
                            key={fieldsType}
                            className={clsx('tab-item-wrapper w-100', {
                              active: activeTab === fieldsType
                            })}
                            index={fieldsType}
                          >
                            <div className="clear-both mt-5"></div>
                            <div className="d-flex flex-wrap px-2 py-2 w-100">
                              {
                                fieldsType === FIELD_TITLES.Fields &&
                                  <Grid container spacing={1}>
                                    <Grid item xl={3} lg={6} md={12}>
                                      <TextField
                                        fullWidth
                                        variant="outlined"
                                        className={`mb-3 ${formClasses.textbox} ${selectedType?.clientName !== selectedType?.baseClientName && 'background-blue'}`}
                                        label="Document Title"
                                        value={selectedType?.clientName || ''}
                                        onChange={handleTitle}
                                      />
                                    </Grid>
                                  </Grid>

                              }
                              <Grid container spacing={1}>
                                {
                                  [
                                    ...(configs.baseConfig[configType][docType]?.[fieldsType] || []).filter(baseField => !(configs.clientConfig?.[configType]?.[docType]?.[fieldsType] || []).some(clientField => clientField.FieldName === baseField.FieldName)),
                                    ...(configs.clientConfig?.[configType]?.[docType]?.[fieldsType] || [])
                                  ]
                                    .filter(({ DisplayController }) => !!DisplayController && DISPLAY_CONTROLLERS_VALUES.includes(DisplayController))
                                    .sort((fieldA, fieldB) => {
                                      if (fieldA.DisplayPosition > fieldB.DisplayPosition) return 1;
                                      if (fieldA.DisplayPosition < fieldB.DisplayPosition) return -1;
                                      return 0;
                                    })
                                    .map(field => {
                                      const FieldNameValue = getFieldValue({
                                        type: configType,
                                        docType,
                                        fieldsType,
                                        fieldName: CONFIG_FIELDS.FieldName,
                                        baseField: field,
                                      });
                                      const LabelValue = getFieldValue({
                                        type: configType,
                                        docType,
                                        fieldsType,
                                        fieldName: CONFIG_FIELDS.Label,
                                        baseField: field,
                                      });
                                      const DisplayControllerValue = getFieldValue({
                                        type: configType,
                                        docType,
                                        fieldsType,
                                        fieldName: CONFIG_FIELDS.DisplayController,
                                        baseField: field,
                                      });
                                      const DisplayPositionValue = getFieldValue({
                                        type: configType,
                                        docType,
                                        fieldsType,
                                        fieldName: CONFIG_FIELDS.DisplayPosition,
                                        baseField: field,
                                      });

                                      return (
                                        <React.Fragment key={`${docType} ${fieldsType} ${field.FieldName}`}>
                                          <Grid item xl={3} lg={6} md={12}>
                                            <RenderField
                                              {
                                                ...{
                                                  item: {
                                                    label: CONFIG_FIELDS.FieldName,
                                                    field: CONFIG_FIELDS.FieldName,
                                                    displayController: DISPLAY_CONTROLLERS.Textbox,
                                                    // source: field.Items || [],
                                                  },
                                                  selectedFieldItems: {
                                                    FieldName: FieldNameValue || field.FieldName
                                                  },
                                                  userHandleChange: (event) => handleChange({
                                                    type: configType,
                                                    docType,
                                                    fieldsType,
                                                    fieldName: CONFIG_FIELDS.FieldName,
                                                    baseField: field,
                                                    event
                                                  }),
                                                  docType
                                                }
                                              }
                                            />
                                          </Grid>
                                          <Grid item xl={3} lg={6} md={12}>
                                            <RenderField
                                              {
                                                ...{
                                                  item: {
                                                    label: CONFIG_FIELDS.Label,
                                                    field: CONFIG_FIELDS.Label,
                                                    displayController: DISPLAY_CONTROLLERS.Textbox
                                                  },
                                                  selectedFieldItems: {
                                                    Label: LabelValue || field.Label
                                                  },
                                                  userHandleChange: (event) => handleChange({
                                                    type: configType,
                                                    docType,
                                                    fieldsType,
                                                    fieldName: CONFIG_FIELDS.Label,
                                                    baseField: field,
                                                    event
                                                  }),
                                                  docType,
                                                  classes: LabelValue && 'background-blue'
                                                }
                                              }
                                            />
                                          </Grid>
                                          <Grid item xl={3} lg={6} md={12}>
                                            <RenderField
                                              {
                                                ...{
                                                  item: {
                                                    label: CONFIG_FIELDS.DisplayController,
                                                    field: CONFIG_FIELDS.DisplayController,
                                                    displayController: DISPLAY_CONTROLLERS.Dropdown,
                                                    source: DISPLAY_CONTROLLERS_VALUES.map(DisplayController => ({ Label: DisplayController, Value: DisplayController })),
                                                  },
                                                  selectedFieldItems: {
                                                    DisplayController: DisplayControllerValue || field.DisplayController
                                                  },
                                                  userHandleChange: (event) => handleChange({
                                                    type: configType,
                                                    docType,
                                                    fieldsType,
                                                    fieldName: CONFIG_FIELDS.DisplayController,
                                                    baseField: field,
                                                    event
                                                  }),
                                                  docType,
                                                  classes: DisplayControllerValue && 'background-blue'
                                                }
                                              }
                                            />
                                          </Grid>
                                          <Grid item xl={3} lg={6} md={12}>
                                            <RenderField
                                              {
                                                ...{
                                                  item: {
                                                    label: CONFIG_FIELDS.DisplayPosition,
                                                    field: CONFIG_FIELDS.DisplayPosition,
                                                    displayController: DISPLAY_CONTROLLERS.NumericBox,
                                                    // source: field.Items || [],
                                                  },
                                                  selectedFieldItems: {
                                                    DisplayPosition: DisplayPositionValue || field.DisplayPosition
                                                  },
                                                  userHandleChange: (event) => handleChange({
                                                    configType: 'clientConfig',
                                                    type: configType,
                                                    docType,
                                                    fieldsType,
                                                    fieldName: CONFIG_FIELDS.DisplayPosition,
                                                    baseField: field,
                                                    event
                                                  }),
                                                  docType,
                                                  classes: `w-85 ${DisplayControllerValue && 'background-blue'}`
                                                }
                                              }
                                            />
                                            {
                                              !(configs.baseConfig?.[configType]?.[docType]?.[fieldsType] || [])
                                                .some(baseField => baseField.FieldName === field.FieldName) &&
                                                  <IconButton
                                                    className="w-15"
                                                    aria-label="remove"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      handleDeleteField({ configType, docType, fieldsType, field })
                                                    }}
                                                  >
                                                    <DeleteIcon />
                                                  </IconButton>
                                            }
                                          </Grid>
                                        </React.Fragment>
                                      )
                                    })
                                }
                              </Grid>
                            </div>
                          </div>
                      ))
                    }
                  </React.Fragment>
                )))
            }
          </CardContent>
      </Card>
      <Dialog open={openModal} onClose={toggleModal} classes={{ paper: 'shadow-lg rounded' }}>
        <div className="text-center p-5">
          <div className="avatar-icon-wrapper rounded-circle m-0">
            <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
              <FontAwesomeIcon icon={['fas', 'times']} className="d-flex align-self-center display-3"/>
            </div>
          </div>
          <h4 className="font-weight-bold mt-4">Are you sure you want to delete this document?</h4>
          <p className="mb-0 font-size-lg text-muted">You cannot undo this operation.</p>
          <div className="pt-4">
            <Button onClick={toggleModal} className="btn-neutral-secondary btn-pill mx-1">
              <span className="btn-wrapper--label"> Cancel </span>
            </Button>
            <Button
              onClick={() => { toggleModal(); } }
              className="btn-danger mx-1"
            >
              <span className="btn-wrapper--label"> Delete </span>
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog
        fullWidth
        scroll="body"
        maxWidth="sm"
        open={openAddFieldModal}
        onClose={toggleAddFieldModal}
        classes={{
          paper: 'modal-content border-0 bg-white rounded-lg p-3 p-xl-0'
        }}
      >
        <DialogTitle disableTypography className={DialogStyles.root}>
          <IconButton aria-label="close" className={DialogStyles.closeButton} onClick={toggleAddFieldModal}>
            <CloseIcon />
          </IconButton>
          <Typography id="form-dialog-title" className="text-lg-center">
            Add New {selectedDoc} Field
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            className="mb-3"
            label="FieldName"
            value={selectedAddField.FieldName || ''}
            onChange={(e) => handleChangeAddField({ FieldName: e.target.value })}
            // InputProps={{
            //   startAdornment: <InputAdornment position="start">{selectedDoc}</InputAdornment>,
            // }}
          />
          <TextField
            fullWidth
            variant="outlined"
            className="mb-3"
            label="Label"
            value={selectedAddField.Label || ''}
            onChange={(e) => handleChangeAddField({ Label: e.target.value })}
          />
          <TextField
            fullWidth
            select
            variant="outlined"
            className="mb-3"
            id="standard-multiline-flexible"
            value={selectedAddField.DisplayController || ''}
            onChange={(e) => handleChangeAddField({ DisplayController: e.target.value })}
            label="DisplayController"
          >
            {
              DISPLAY_CONTROLLERS_VALUES.map(DisplayController =>(<MenuItem key={DisplayController} value={DisplayController}>{DisplayController}</MenuItem>))
            }
          </TextField>
          {
            selectedAddField.DisplayController === DISPLAY_CONTROLLERS.Dropdown &&
              <TextField
                fullWidth
                select
                variant="outlined"
                className="mb-3"
                value={selectedAddField.Source || ''}
                onChange={(e) => handleChangeAddField({ Source: e.target.value })}
                label="Source"
              >
                {
                  Object.keys(configs.systemFields.Fields)
                    .filter(sourceName => Array.isArray(configs.systemFields.Fields[sourceName]))
                    .map(sourceName =>(<MenuItem key={sourceName} value={`Fields:${sourceName}`}>{sourceName}</MenuItem>))
                }
                {
                  Object.keys((configs.systemFields[`${selectedDoc}Fields`] || []))
                    .filter(sourceName => Array.isArray(configs.systemFields[`${selectedDoc}Fields`][sourceName]))
                    .map(sourceName =>(<MenuItem key={sourceName} value={`${`${selectedDoc}Fields`}:${sourceName}`}>{sourceName}</MenuItem>))
                }
              </TextField>
          }
          <TextField
            fullWidth
            variant="outlined"
            className="mb-3"
            label="DisplayPosition"
            type={'number'}
            value={selectedAddField.DisplayPosition || ''}
            onChange={(e) => handleChangeAddField({ DisplayPosition: e.target.value })}
          />
        </DialogContent>
        <DialogActions className="p-4">
          <Button
            onClick={toggleAddFieldModal}
            className="btn-neutral-secondary text-danger">
            <span className="btn-wrapper--label">Cancel</span>
          </Button>
          <Button onClick={handleSaveAddField} className="btn-primary m-2">
            <span className="btn-wrapper--label">Save</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state) => ({
  pageTitle: state.ThemeOptions.pageTitle
});

const mapDispatchToProps = (dispatch) => ({
  setPageTitle: (pageTitle) => dispatch(setPageTitle(pageTitle)),
  setPageButtonsProps: (pageButtonsProps) => dispatch(setPageButtonsProps(pageButtonsProps))
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomFields);
